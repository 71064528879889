import {
    GtmMeshDetectHolesParams,
    GtmMeshDetectSelfIntersectionsParams,
    GtmMeshRemoveDegenerateTriangleParams,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GeoscienceObject } from 'src/types/core.types';

// Note: A project assumes the user's current org+workspace combination

export interface GtmProjectInput extends GeoscienceObject {}
export interface GtmEvoOutputObject extends GeoscienceObject {}
export interface AggregatableObject extends GeoscienceObject {
    isAggregated: boolean;
}

export interface GtmPoint {
    x: number;
    y: number;
    z: number;
}

export interface GtmBounds {
    minPoint: GtmPoint;
    maxPoint: GtmPoint;
    rotationAngle: number;
}

export interface GtmModelSettings {
    backgroundColor: string;
    units: string;
    showXyzAxis: boolean;
}

export interface GtmRemeshSettings {
    patchAngleTolerance: number;
    maxChordalError: number;
    targetH: number;
    shapeQualityWeight: number;
}

export interface GtmAnalyticalModelSettings {
    degenerateTriangleSettings: GtmMeshRemoveDegenerateTriangleParams;
    holeSettings: GtmMeshDetectHolesParams;
    selfIntersectionSettings: GtmMeshDetectSelfIntersectionsParams;
    remeshSettings: GtmRemeshSettings;
}

/**
 * Model is a reusable template used to create analytical models. It contains a subset of the objects in a workspace
 */
export interface GtmModel {
    id?: string;
    name?: string;
    inputObjects?: GtmProjectInput[];
    settings?: GtmModelSettings;
}

export interface GtmAnalyticalModel extends GtmModel {
    // The bounds of the analytical model defines a boundaryId which
    // is used to build the path for the aggregate geometry.
    // Since each aggregate geometry is titled "Aggregate Geometry", this
    // allows us to have multiple aggregate geometries in the workspace
    // without conflict.
    bounds: GtmBounds;
    objects: AggregatableObject[];
    aggregateGeometry: GtmEvoOutputObject;
    analyticalModelSettings: GtmAnalyticalModelSettings;
    volumes?: GtmEvoOutputObject[];
    showVolumes?: boolean;
    parameterizedGeometryList?: GtmEvoOutputObject[];
}

export function isGtmAnalyticalModel(
    model: GtmModel | GtmAnalyticalModel,
): model is GtmAnalyticalModel {
    return ((model ?? {}) as GtmAnalyticalModel).bounds !== undefined;
}

export interface GtmHistoryOperation {
    description: string;
}

export interface GtmHistoryEntry {
    operation: GtmHistoryOperation;
    versionId: string;
}

export interface GtmProject {
    name: string;
    projectDescription?: string;
    history: GtmHistoryEntry[];
    models: (GtmModel | GtmAnalyticalModel)[];
}
