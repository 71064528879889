/* eslint-disable import/no-cycle */

import { gooseClient } from '@local/api-clients/src/goose/enhancedGooseClient';
import { middleware as loginMiddleware, reducers as loginReducers } from '@local/login';
import { workspaceClient } from '@local/workspaces/dist/apiClients/workspaceClient';
import { configureStore, combineReducers, Reducer } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { fileClient } from 'src/apiClients/file/fileClient';
import { gtmComputeApi } from 'src/apiClients/gtmCompute/gtmComputeApi';

import { evoSlice } from './evo/evoSlice';
import { issuesSlice } from './issues/issuesSlice';
import { projectSlice } from './project/projectSlice';
import { detectionSettingsSlice } from './ui/detectionSettings';
import { projectPanelSlice } from './ui/projectPanel';
import { publishPanelSlice } from './ui/publishPanel';
import { remeshSettingsSlice } from './ui/remeshSettings';
import { visualizationSlice } from './visualization/visualizationSlice';

const combinedReducer = combineReducers({
    [fileClient.reducerPath]: fileClient.reducer,
    [gtmComputeApi.reducerPath]: gtmComputeApi.reducer,
    [gooseClient.reducerPath]: gooseClient.reducer,
    [visualizationSlice.name]: visualizationSlice.reducer,
    [workspaceClient.reducerPath]: workspaceClient.reducer,
    [evoSlice.name]: evoSlice.reducer,
    [projectSlice.name]: projectSlice.reducer,
    [issuesSlice.name]: issuesSlice.reducer,
    [projectPanelSlice.name]: projectPanelSlice.reducer,
    [detectionSettingsSlice.name]: detectionSettingsSlice.reducer,
    [remeshSettingsSlice.name]: remeshSettingsSlice.reducer,
    [publishPanelSlice.name]: publishPanelSlice.reducer,
    ...loginReducers,
});

const rootReducer: Reducer<RootState> = (state, action) => {
    if (action.type === 'store/reset') {
        return {} as RootState;
    }
    return combinedReducer(state, action);
};

export const createStore = () =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: {
                    ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
                },
            }).concat(
                fileClient.middleware,
                gtmComputeApi.middleware,
                gooseClient.middleware,
                workspaceClient.middleware,
                ...loginMiddleware,
            ),
    });

export const store = createStore();

// Infer the `RootState` from combinedReducer
export type RootState = ReturnType<typeof combinedReducer>;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
