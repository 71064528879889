import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import CheckCircle from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

import { ActionDialog } from 'src/components/ActionDialog/ActionDialog';
import { PercentProgress } from 'src/components/PercentProgress/PercentProgress';
import { TransformationStatus } from 'src/hooks/transformation/useTransformationManager';

const iconsByStatus = new Map<TransformationStatus, JSX.Element>([
    [
        TransformationStatus.Transforming,
        <BuildCircleOutlinedIcon color="primary" fontSize="large" />,
    ],
    [TransformationStatus.Uploading, <BuildCircleOutlinedIcon color="primary" fontSize="large" />],
    [TransformationStatus.Complete, <CheckCircle color="success" fontSize="large" />],
    [TransformationStatus.Failed, <WarningIcon color="error" fontSize="large" />],
]);

export function TransformationProgressModal({
    transformationStatus,
    transformationTitles,
    transformationMessages,
    transformationPercentages,
}: {
    transformationStatus: TransformationStatus | undefined;
    transformationTitles: Map<TransformationStatus, string>;
    transformationMessages?: Map<TransformationStatus, string>;
    transformationPercentages?: Map<TransformationStatus, number>;
}) {
    const percentage = transformationPercentages?.get(transformationStatus!);

    const hasPercentages = transformationPercentages !== undefined && percentage !== undefined;
    const progressBar =
        hasPercentages && transformationStatus !== TransformationStatus.Failed ? (
            <PercentProgress percent={percentage} showPercentText />
        ) : undefined;

    const title =
        transformationStatus !== undefined
            ? transformationTitles.get(transformationStatus) ?? ''
            : '';
    const message =
        transformationStatus !== undefined
            ? transformationMessages?.get(transformationStatus) ?? ''
            : undefined;

    return (
        <ActionDialog
            open={transformationStatus !== undefined}
            title={title}
            message={message}
            progressBar={progressBar}
            icon={
                transformationStatus !== undefined
                    ? iconsByStatus.get(transformationStatus)
                    : undefined
            }
        />
    );
}
