import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context';
import UploadIcon from '@mui/icons-material/FileUploadOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import ShowIcon from '@mui/icons-material/RemoveRedEye';
import HideIcon from '@mui/icons-material/VisibilityOff';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import {
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2Root,
    TreeItem2GroupTransition,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import { MouseEvent, useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { BoxSearchIcon } from 'src/assets/BoxSearchIcon';
import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import {
    AggregatableObject,
    GtmAnalyticalModel,
    GtmEvoOutputObject,
    GtmProjectInput,
    isGtmAnalyticalModel,
} from 'src/gtmProject';
import { useSceneObjectDataManager, useSceneObjectSelectionManager } from 'src/hooks';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { selectDoesCurrentModelObjectHaveIssues } from 'src/store/issues/selectors';
import {
    deselectCurrentModelSelectedObject,
    removeInputObjectFromModelInCurrentProject,
    setCurrentModelAggregateObjectAsSelected,
    setCurrentModelSelectedObjectIndex,
} from 'src/store/project/projectSlice';
import {
    selectCurrentModelInputObjects,
    selectCurrentModelSelectedObjectIndex,
    selectCurrentProjectData,
    selectSelectedModelIndex,
    selectCurrentShowVolumes,
    selectCurrentProjectVolumes,
    selectCurrentModelSelectedObject,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    ObjectsTabSelection,
    selectShouldShowSetBoundaryDialog,
    setObjectsPanelTabSelection,
    setOpenUploadObjectsDialog,
    setShouldShowSetBoundaryDialog,
} from 'src/store/ui/projectPanel';
import { DEFAULT_LIST_MAX_HEIGHT } from 'src/styles';
import { ObjectId } from 'src/types/core.types';
import { fileNameExtensionRemover } from 'src/utils';
import { AggregateControl } from 'src/visualization/ProjectPanel/components/ObjectsPanel/AggregateControl';
import {
    CREATE_ANALYTICAL_BOUNDARY_LABEL,
    EMPTY_OBJECTS_MESSAGE,
    NO_OBJECTS_YET_MESSAGE,
    UPLOAD_LABEL,
    WORKSPACE_LABEL,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

import { ResetAggregateControl } from './ResetAggregateControl';

export function ModelObjectsTab() {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const currentModelInputObjects = useAppSelector(selectCurrentModelInputObjects);
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const showSetBoundaryDialog = useAppSelector(selectShouldShowSetBoundaryDialog);

    const projectData = useAppSelector(selectCurrentProjectData);
    const currentModel = projectData.models?.[selectedModelIndex];
    const isAnalyticalModel = isGtmAnalyticalModel(projectData.models?.[selectedModelIndex]);
    const currentAggregateObject = isAnalyticalModel
        ? (currentModel as GtmAnalyticalModel).aggregateGeometry
        : undefined;

    const currentModelObjects: GtmProjectInput[] | AggregatableObject[] = isAnalyticalModel
        ? (currentModel as GtmAnalyticalModel).objects
        : currentModelInputObjects;

    if (currentModelObjects.length === 0) {
        return <ModelObjectsEmptyState />;
    }
    return (
        <>
            <Box p={2}>
                <Box
                    sx={{
                        maxHeight: DEFAULT_LIST_MAX_HEIGHT,
                        overflowY: 'auto',
                        borderRadius: theme.spacing(0.5),
                        border: 1,
                        borderColor: 'divider',
                    }}
                >
                    <List dense disablePadding>
                        {currentModelObjects.map((object, index) => (
                            <ModelObjectsListItem
                                key={object.id}
                                index={index}
                                object={object}
                                isLastItem={index === currentModelObjects.length - 1}
                            />
                        ))}
                    </List>
                </Box>
                {!showSetBoundaryDialog && !isAnalyticalModel && (
                    <Button
                        sx={{ marginTop: theme.spacing(2), width: '100%' }}
                        size="small"
                        variant="outlined"
                        onClick={() => dispatch(setShouldShowSetBoundaryDialog(true))}
                    >
                        {CREATE_ANALYTICAL_BOUNDARY_LABEL}
                    </Button>
                )}
            </Box>
            {currentAggregateObject && (
                <Box p={2} pt={0}>
                    <Box
                        sx={{
                            maxHeight: DEFAULT_LIST_MAX_HEIGHT,
                            overflowY: 'auto',
                            borderRadius: theme.spacing(0.5),
                            border: 1,
                            borderColor: 'divider',
                        }}
                    >
                        <AggregateModelObject
                            aggregateObject={currentAggregateObject}
                            isAnyObjectAggregated={(
                                currentModelObjects as AggregatableObject[]
                            ).some((obj) => obj.isAggregated)}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
}

function AggregateModelObject({
    aggregateObject,
    isAnyObjectAggregated,
}: Readonly<{
    aggregateObject: GtmEvoOutputObject;
    isAnyObjectAggregated: boolean;
}>) {
    const applyTrace = useTrace('aggregate-object');
    const dispatch = useAppDispatch();
    const { onObjectControlSelection, onObjectDeselect } = useSceneObjectSelectionManager();
    const { isObjectOnPlotByObjectId } = useSceneObjectDataManager();
    const { getZoomToViewTool } = useBaseXyz();
    const currentModelSelectedObject = useAppSelector(selectCurrentModelSelectedObject);
    const currentVolumes = useAppSelector(selectCurrentProjectVolumes);
    const showVolumes = useAppSelector(selectCurrentShowVolumes);
    const isAggregateSelected = currentModelSelectedObject?.id === aggregateObject.id;
    const [shouldExpand, setShouldExpand] = useState(showVolumes);

    const handleItemSelectionToggle = (
        event: React.SyntheticEvent,
        itemId: string,
        isSelected: boolean,
    ) => {
        // Select the aggregate if the aggregate item itself or any of the children are selected.
        // TODO: This behaviour should change if we'd like to set appearance of the volumes in the
        // "Appearance" panel.
        const itemIsTheAggregate = itemId === aggregateObject.id;
        const itemIsSomeVolume = currentVolumes.some((v) => v.id === itemId);
        const shouldSelectAggregate = itemIsTheAggregate || itemIsSomeVolume;
        if (isAggregateSelected && !shouldSelectAggregate) {
            dispatch(deselectCurrentModelSelectedObject());
            onObjectDeselect(aggregateObject.id as ObjectId);
        } else if (!isAggregateSelected && shouldSelectAggregate) {
            onObjectControlSelection([], 0, aggregateObject.id, event as React.MouseEvent);
            dispatch(setCurrentModelAggregateObjectAsSelected());
        }

        if (isSelected && isObjectOnPlotByObjectId(itemId)) {
            getZoomToViewTool().zoomToView(itemId);
        }
    };

    useEffect(() => {
        setShouldExpand(showVolumes);
    }, [showVolumes]);

    return (
        <SimpleTreeView
            onItemSelectionToggle={handleItemSelectionToggle}
            selectedItems={isAggregateSelected ? aggregateObject.id : ''}
            expandedItems={shouldExpand ? [aggregateObject.id] : []}
        >
            <ObjectTreeItem
                onClick={() => setShouldExpand(!shouldExpand)}
                automation-id={applyTrace('root-item')}
                key={aggregateObject.id}
                itemId={aggregateObject.id}
                label={aggregateObject.name}
                object={aggregateObject}
                additionalElements={isAnyObjectAggregated ? [<ResetAggregateControl />] : undefined}
            >
                {showVolumes &&
                    currentVolumes.map((volume, index) => (
                        <ObjectTreeItem
                            onClick={(event) => {
                                // We don't want to toggle the expansion of the parent item when
                                // clicking on the children.
                                event.stopPropagation();
                            }}
                            automation-id={applyTrace(`volume-item-${index}`)}
                            key={volume.id}
                            itemId={volume.id}
                            label={volume.name}
                            object={volume}
                        />
                    ))}
            </ObjectTreeItem>
        </SimpleTreeView>
    );
}

interface ObjectTreeItemProps
    extends Omit<UseTreeItem2Parameters, 'rootRef'>,
        Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> {
    object: GtmEvoOutputObject;
    additionalElements?: React.ReactNode[];
}

// Customized tree item. We need this because we don't follow the standard tree "anatomy".
// See: https://mui.com/x/react-tree-view/tree-item-customization/#anatomy
const ObjectTreeItem = forwardRef((props: ObjectTreeItemProps, ref: React.Ref<HTMLLIElement>) => {
    const {
        id,
        itemId,
        label,
        disabled,
        children,
        object: objectIdWithVersion,
        additionalElements,
        ...other
    } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getGroupTransitionProps,
        status,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <TreeItem2Content {...getContentProps()} sx={{ padding: 0.25, paddingRight: 1 }}>
                    <HideShowButtons object={objectIdWithVersion} />
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <ModelObjectName object={objectIdWithVersion} />
                    {additionalElements}
                </TreeItem2Content>
                <TreeItem2GroupTransition {...getGroupTransitionProps()} />
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});

function HideShowButtons({ object }: Readonly<{ object: GtmEvoOutputObject | GtmProjectInput }>) {
    const { loadGtmObject, isObjectOnPlotByObjectId, removeGtmObject } =
        useSceneObjectDataManager();
    // We keep track of the state here so that a re-render occurs
    // when the object is added/removed from the plot.
    // If this is just
    // const isObjectOnPlot = isObjectOnPlotByObjectId(object.id);
    // Then no re-render will occur when the buttons are clicked.
    const [isObjectOnPlot, setIsObjectOnPlot] = useState(isObjectOnPlotByObjectId(object.id));

    const handleHideItemOnClick = (event: MouseEvent) => {
        event.stopPropagation();
        removeGtmObject(object.id);
        setIsObjectOnPlot(isObjectOnPlotByObjectId(object.id));
    };

    const handleShowItemOnClick = (event: MouseEvent) => {
        event.stopPropagation();
        loadGtmObject(object.id, object.version, object.name);
        setIsObjectOnPlot(isObjectOnPlotByObjectId(object.id));
    };

    return (
        <ListItemIcon sx={{ minWidth: '36px' }}>
            {isObjectOnPlot ? (
                <IconButton
                    edge="end"
                    onClick={(event) => handleHideItemOnClick(event)}
                    size="small"
                >
                    <ShowIcon />
                </IconButton>
            ) : (
                <IconButton
                    edge="end"
                    onClick={(event) => handleShowItemOnClick(event)}
                    size="small"
                >
                    <HideIcon />
                </IconButton>
            )}
        </ListItemIcon>
    );
}

function ModelObjectName({
    object,
    sx = [],
}: Readonly<{ object: GtmEvoOutputObject | GtmProjectInput; sx?: SxProps<Theme> }>) {
    const doesObjectHaveIssues = useSelector(selectDoesCurrentModelObjectHaveIssues(object.id));

    return (
        <ListItemText
            disableTypography
            primary={
                <Stack direction="row" spacing={1.25} paddingRight="5px">
                    <OverflowTooltipTypography
                        variant="body2"
                        sx={[
                            {
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                            ...(Array.isArray(sx) ? sx : [sx]),
                        ]}
                    >
                        {fileNameExtensionRemover(object.name)}
                    </OverflowTooltipTypography>
                    {doesObjectHaveIssues && (
                        // Note the space child is not a mistake, it's so that the fallback avatar is not used
                        <Avatar
                            sx={(theme) => ({
                                bgcolor: theme.palette.warning.light,
                                height: theme.spacing(1),
                                width: theme.spacing(1),
                                color: theme.palette.common.black,
                                transform: `translate(0px, -${theme.spacing(1)}px)`,
                                marginRight: theme.spacing(1),
                            })}
                        >
                            {' '}
                        </Avatar>
                    )}
                </Stack>
            }
        />
    );
}

export function ModelObjectsListItem({
    object,
    index,
    isLastItem,
}: Readonly<{
    object: GtmProjectInput | AggregatableObject;
    index: number;
    isLastItem?: boolean;
}>) {
    const dispatch = useAppDispatch();
    const { removeGtmObject, isObjectOnPlotByObjectId } = useSceneObjectDataManager();
    const currentModelSelectedObjectIndex = useAppSelector(selectCurrentModelSelectedObjectIndex);
    const { getZoomToViewTool } = useBaseXyz();
    const { syncProject } = useProjectSynchronizer();
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const projectData = useAppSelector(selectCurrentProjectData);
    const { onObjectControlSelection, onObjectDeselect } = useSceneObjectSelectionManager();

    const isAnalyticalModel = isGtmAnalyticalModel(projectData.models?.[selectedModelIndex]);
    const isAggregated =
        isAnalyticalModel &&
        (projectData.models[selectedModelIndex] as GtmAnalyticalModel).objects[index].isAggregated;

    const handleRemoveItemOnClick = async (event: MouseEvent) => {
        event.stopPropagation();
        dispatch(removeInputObjectFromModelInCurrentProject([selectedModelIndex, object]));
        syncProject();
        removeGtmObject(object.id);
    };

    const handleOnClick = (event: MouseEvent) => {
        if (currentModelSelectedObjectIndex === index) {
            dispatch(deselectCurrentModelSelectedObject());
            onObjectDeselect(object.id);
        } else {
            // TODO: Handle multiselect
            onObjectControlSelection([], index, object.id, event);
            dispatch(setCurrentModelSelectedObjectIndex(index));
            if (isObjectOnPlotByObjectId(object.id)) {
                getZoomToViewTool().zoomToView(object.id);
            }
        }
    };

    return (
        <ListItem disableGutters disablePadding divider={!isLastItem}>
            <ListItemButton
                selected={currentModelSelectedObjectIndex === index}
                sx={(theme) => ({ padding: theme.spacing(0.5, 1) })}
                onClick={handleOnClick}
            >
                <HideShowButtons object={object} />
                <ModelObjectName object={object} />
                {isAnalyticalModel && !isAggregated && (
                    <>
                        <AggregateControl
                            handleRemoveObject={() => {
                                removeGtmObject(object.id);
                            }}
                            inputMesh={object}
                        />
                        <IconButton edge="end" onClick={handleRemoveItemOnClick} size="small">
                            <RemoveIcon />
                        </IconButton>
                    </>
                )}
            </ListItemButton>
        </ListItem>
    );
}

function ModelObjectsEmptyState() {
    const applyTrace = useTrace('model-objects-empty-state');
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const handleWorkspaceOnClick = () => {
        dispatch(setObjectsPanelTabSelection(ObjectsTabSelection.WorkSpaceObjects));
    };

    const handleUploadObjectsOnClick = () => {
        dispatch(setOpenUploadObjectsDialog(true));
    };

    return (
        <Stack
            automation-id={applyTrace('root')}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                padding: theme.spacing(3),
            }}
        >
            <BoxSearchIcon fontSize="large" />
            <Typography sx={{ margin: theme.spacing(0.5, 0, 1, 0) }} color="secondary" variant="h5">
                {NO_OBJECTS_YET_MESSAGE}
            </Typography>
            <Typography color="secondary" variant="subtitle2">
                {EMPTY_OBJECTS_MESSAGE}
            </Typography>
            <Stack
                spacing={2}
                sx={{ marginTop: theme.spacing(2), textTransform: 'capitalize' }}
                direction="row"
            >
                <Button
                    automation-id={applyTrace('workspace-button')}
                    variant="outlined"
                    size="small"
                    startIcon={<FolderIcon />}
                    onClick={handleWorkspaceOnClick}
                >
                    {WORKSPACE_LABEL}
                </Button>
                <Button
                    automation-id={applyTrace('upload-button')}
                    variant="contained"
                    size="small"
                    startIcon={<UploadIcon />}
                    onClick={handleUploadObjectsOnClick}
                >
                    {UPLOAD_LABEL}
                </Button>
            </Stack>
        </Stack>
    );
}
