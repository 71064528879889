import { BreadcrumbSegmentItem } from '@local/web-design-system/dist/components/Breadcrumb';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import { fetchWorkspaces } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { useEvoContextManager } from 'src/hooks/evoContext/useEvoContextManager';

import { ThemeSelector } from '../ThemeSelector/ThemeSelector';
import { useStyles } from './GtmBreadcrumbs.styles';
import { WorkspaceSelectionTable } from './SelectionTables/WorkspaceSelectionTable';

function SegmentDivider() {
    const { classes } = useStyles();
    return (
        <Grid item className={classes.divider}>
            <Typography className={classes.name}>/</Typography>
        </Grid>
    );
}

export function SkeletonWorkspaceContents() {
    return (
        <Grid>
            <Skeleton variant="rectangle" height={32} />
        </Grid>
    );
}

export function GtmBreadcrumbs() {
    const { classes } = useStyles();
    const [display, setDisplay] = React.useState(false);
    const anchorRef = React.useRef(null);
    const bpRef = React.useRef<HTMLDivElement>(null);
    const { workspaceUuid } = useParams();
    const { swapWorkspace } = useEvoContextManager();

    const params = useParams();

    const { data: workspaceData, isLoading } = fetchWorkspaces({
        isAdmin: false,
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        limit: 500,
        sort: 'name',
    });

    const workspaceName = workspaceData?.results.find(
        (workspace) => workspace.id === workspaceUuid,
    )?.name;

    React.useEffect(() => {
        if (workspaceName) {
            swapWorkspace(workspaceName);
        }
    }, [workspaceName]);

    const toggleDisplay = React.useCallback(() => {
        setDisplay(!display);
    }, [display]);

    const segment = {
        name: workspaceName || `Select workspace`,
        onClick: toggleDisplay,
    };
    const projectSegment = { name: 'Workspace', onClick: toggleDisplay };

    return (
        <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justifyContent="flex-start"
            ref={anchorRef}
            className={classes.breadcrumbRow}
        >
            <Grid item className={classes.breadcrumb}>
                <BreadcrumbSegmentItem segment={projectSegment} isLink medium />
            </Grid>
            <SegmentDivider />
            <BreadcrumbSegmentItem segment={segment} isLink={false} medium showArrow />
            <Popover
                open={display}
                onClose={() => setDisplay(false)}
                elevation={0}
                anchorEl={anchorRef?.current}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                classes={{ paper: classes.branchHistory }}
            >
                <Grid container ref={bpRef}>
                    {isLoading ? (
                        <SkeletonWorkspaceContents />
                    ) : (
                        <WorkspaceSelectionTable data={workspaceData} />
                    )}
                </Grid>
            </Popover>
            <ThemeSelector />
        </Grid>
    );
}
