import { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { detectorActionToDefectString } from 'src/utils/typeTransformations';

import {
    generateHolesBoundary,
    generatePointsHighlight,
    generateTriangleBoundary,
    generateEdgeHighlight,
    generateNonPartitioningSurfaceHighlight,
} from './highlightGenerator';
import { DefectsHighlight } from './types';

const HIGHLIGHT_FACTORY = {
    [GtmMeshDetectorAction.DetectDegenerateTris]: generateTriangleBoundary,
    [GtmMeshDetectorAction.DetectDuplicatePoints]: generatePointsHighlight,
    [GtmMeshDetectorAction.DetectDuplicateTris]: generateTriangleBoundary,
    // TODO: [GtmMeshDetectorAction.DetectFins]: ,
    [GtmMeshDetectorAction.DetectHoles]: generateHolesBoundary,
    [GtmMeshDetectorAction.DetectSelfIntersections]: generateTriangleBoundary,
    [GtmMeshDetectorAction.DetectNonPartitioningSurfaces]: generateNonPartitioningSurfaceHighlight,
    [GtmMeshDetectorAction.DetectNonManifoldEdges]: generateEdgeHighlight,
    [GtmMeshDetectorAction.DetectNonManifoldVertices]: generatePointsHighlight,
    [GtmMeshDetectorAction.DetectInconsistentlyOrientedTris]: generateTriangleBoundary,
};

export type HighlightClasses = keyof typeof HIGHLIGHT_FACTORY;

export function generateHighlightSnapshot(action: HighlightClasses, state: DefectsHighlight) {
    const highlightGenerator = HIGHLIGHT_FACTORY[action];
    if (highlightGenerator) {
        const defectName = detectorActionToDefectString(action);
        return highlightGenerator(defectName, state as DefectsHighlight);
    }
    return null;
}
