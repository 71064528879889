import {
    GtmMeshFillHoleParams,
    GtmMeshRemoveDegenerateTriangleParams,
    GtmMeshTransformationAction,
    GtmMeshTransformationParams,
    GtmRemeshParams,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmHistoryOperation } from 'src/gtmProject';

const removeUnusedVerticesDescription = 'Remove unused vertices.';
const parameterizeVolumesDescription = 'Parameterize volumes';

const removeDegenerateTriangleDescription = (
    params: GtmMeshRemoveDegenerateTriangleParams,
): string =>
    `Remove degenerate triangles with cap minimum angle of ${params.capMinAngleDegrees} and needle threshold ratio of ${params.needleThresholdRatio}`;

const fillHoleDescription = (params: GtmMeshFillHoleParams): string =>
    `Fill hole with fill mode ${params.fillMode}`;

// TODO: may want to add more details depending on what we expose to the user, or summarize differently
const remeshDescription = (params: GtmRemeshParams): string =>
    `Remesh with patch angle tolerance of ${params.patchAngleTolerance} and max chordal error of ${params.maxChordalError}`;

/**
 * Summarize the history operation for a transformation action
 */
export function summarizeTransformationActionHistoryOperation(
    action: GtmMeshTransformationAction,
    params: GtmMeshTransformationParams,
): GtmHistoryOperation {
    switch (action) {
        case GtmMeshTransformationAction.RemoveDegenerateTris: {
            return {
                description: removeDegenerateTriangleDescription(
                    params as GtmMeshRemoveDegenerateTriangleParams,
                ),
            };
        }
        case GtmMeshTransformationAction.RemoveUnusedPoints:
            return {
                description: removeUnusedVerticesDescription,
            };
        case GtmMeshTransformationAction.ParameterizeVolumes:
            return {
                description: parameterizeVolumesDescription,
            };
        case GtmMeshTransformationAction.FillHoles:
            return {
                description: fillHoleDescription(params as GtmMeshFillHoleParams),
            };
        case GtmMeshTransformationAction.Remesh:
            return {
                description: remeshDescription(params as GtmRemeshParams),
            };
        default:
            return {
                description: `${action}`,
            };
    }
}
