import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';

import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';

export const THEME_MAP: { [key: string]: string } = {
    light: 'light',
    dark: 'dark',
};

export function ThemeSelector() {
    const themeContext = useContext(WDS2ThemeContext);

    const handleChange = () => {
        themeContext.toggleTheme();
    };

    return (
        <>
            <Typography component="h4" color="#767375" padding="4px">
                {themeContext.theme}
            </Typography>
            <Box>
                <FormControl fullWidth>
                    <FormControlLabel
                        control={<Switch defaultChecked onChange={handleChange} />}
                        label=""
                    />
                </FormControl>
            </Box>
        </>
    );
}
