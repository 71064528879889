import { createSelector } from '@reduxjs/toolkit';

import { AGGREGATE_GEOMETRY_NAME } from 'src/constants';
import { RootState } from 'src/store/store';
import { PublishPanelState } from 'src/store/ui/publishPanel/publishPanel.types';

export const initialPublishPanelState: PublishPanelState = {
    shouldShowPublishPanel: false,
    modelExportName: AGGREGATE_GEOMETRY_NAME,
};

type SelectorTypeString = (state: RootState) => string;
type SelectorTypeBoolean = (state: RootState) => boolean;

export const publishPanelState = (state: RootState): PublishPanelState => state.publishPanel;
export const selectShouldShowPublishPanel: SelectorTypeBoolean = createSelector(
    publishPanelState,
    (publishPanelStateRoot) => publishPanelStateRoot.shouldShowPublishPanel,
);
export const selectModelExportName: SelectorTypeString = createSelector(
    publishPanelState,
    (publishPanelStateRoot) => publishPanelStateRoot.modelExportName,
);
