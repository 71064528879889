import { useState } from 'react';

import { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { selectedSceneObjects } from 'src/store/visualization/selectors';
import { addOrUpdateSceneObject } from 'src/store/visualization/visualizationSlice';
import { ObjectId } from 'src/types/core.types';

export function useSceneObjectSelectionManager() {
    const dispatch = useAppDispatch();
    const selectedObjects = useAppSelector(selectedSceneObjects);

    const [lastSelected, setLastSelected] = useState<number | null>(null);

    const onObjectControlSelection = (
        data: GtmEvoOutputObject[],
        index: number,
        objectId: string,
        event: React.MouseEvent | React.DragEvent,
    ) => {
        if (event.type === 'click' && !event.shiftKey && !event.ctrlKey) {
            Object.entries(selectedObjects).forEach(([id]) => {
                dispatch(addOrUpdateSceneObject([id, { isSelected: false }]));
            });
            dispatch(addOrUpdateSceneObject([objectId, { isSelected: true }]));
            setLastSelected(index);
        }
        if (event.type === 'click' && event.ctrlKey) {
            dispatch(addOrUpdateSceneObject([objectId, { isSelected: true }]));
            setLastSelected(index);
        }
        if (event.type === 'click' && event.shiftKey) {
            if (lastSelected !== null) {
                const start = Math.min(lastSelected, index);
                const end = Math.max(lastSelected, index);
                for (let i = start; i <= end; i += 1) {
                    dispatch(addOrUpdateSceneObject([data[i].id, { isSelected: true }]));
                }
                setLastSelected(index);
            } else {
                Object.entries(selectedObjects).forEach(([id]) => {
                    dispatch(addOrUpdateSceneObject([id, { isSelected: false }]));
                });
                dispatch(addOrUpdateSceneObject([objectId, { isSelected: true }]));
                setLastSelected(index);
            }
        }
    };

    const onObjectDeselect = (objectId: ObjectId) => {
        dispatch(addOrUpdateSceneObject([objectId, { isSelected: false }]));
    };

    return {
        onObjectControlSelection,
        onObjectDeselect,
    };
}
