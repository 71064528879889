import { common as designStyles } from '@local/web-design-system/dist/styles/common';
import { fonts } from '@local/web-design-system/dist/styles/fonts';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        container: {
            ...designStyles(theme).thinScrollBar,
            minHeight: theme.spacing(18.5),
        },
        maxHeightStyle: {
            maxHeight: `calc(100vh - ${theme.spacing(34.25)})`,
        },
        dropDownContainer: {
            minHeight: 0,
            maxHeight: theme.spacing(80),
        },
        sceneShareContainer: {
            minHeight: 0,
            maxHeight: theme.spacing(22),
        },
        reducedHeightForOverviewPage: {
            maxHeight: `calc(100vh - ${theme.spacing(81)})`,
        },
        footer: {
            color: theme.palette.grey[500],
            padding: theme.spacing(1, 0),
        },
        historyTable: {
            // The column sizes are based on the size of the first row, which happens to be our header.
            tableLayout: 'fixed',
            borderCollapse: 'unset',
        },
        graphCell: {
            // Spacing on graph cell is defined dynamically
            whiteSpace: 'nowrap',
        },
        graphIcon: {
            height: theme.spacing(1.25),
            padding: theme.spacing(),
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            width: theme.spacing(2),
        },
        idCell: {
            // Fix the size of most of our columns, except for...
            width: theme.spacing(4.5),
        },
        lockCell: {
            width: theme.spacing(2),
        },
        branchCell: {
            width: theme.spacing(13),
        },
        noteCell: {
            // ...the note. Mad as it seems a max-width of 0 is as big as it can get.
            maxWidth: 0,
        },
        dateCell: {
            width: theme.spacing(11),
        },
        stageCell: {
            width: theme.spacing(13),
        },
        userCell: {
            width: theme.spacing(13),
        },
        overflowCell: {
            width: theme.spacing(4),
        },
        publicTagCell: {
            width: theme.spacing(4),
        },
        permissionCell: {
            width: theme.spacing(7),
        },
        loadingOverlay: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundColor: theme.palette.grey[100],
            opacity: 0.7,
            zIndex: 999,
        },
        emptyStateRoot: {
            paddingBottom: theme.spacing(12),
        },
        errorContainer: {
            padding: theme.spacing(3),
        },
        errorNotification: {
            backgroundColor: theme.palette.grey[50],
        },
        footerLink: {
            color: theme.palette.primary.main,
            padding: theme.spacing(1, 0),
            textDecoration: 'underline',
        },
        divider: {
            width: '1px',
            height: '100%',
            paddingTop: theme.spacing(2),
            margin: theme.spacing(),
            background: theme.palette.grey[500],
        },
        graphButton: {
            padding: theme.spacing(0, 1),
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
        tileHeaderText: {
            color: theme.palette.grey[900],
        },
        tileHeaderCell: {
            backgroundColor: theme.palette.grey[100],
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
        dropDownHeaderText: {
            color: theme.palette.grey[900],
            fontSize: '12px',
            fontWeight: fonts.weights.medium,
        },
        dropDownTableHeader: {
            '& tr': { height: theme.spacing(5) },
        },
    }) as const;

export const useStyles = makeStyles()(styles);
