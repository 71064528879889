import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import CheckCircle from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

import { ActionDialog } from 'src/components/ActionDialog/ActionDialog';
import { CloseRetryActions } from 'src/components/ActionDialog/CloseRetryActions';
import { PercentProgress } from 'src/components/PercentProgress/PercentProgress';
import { BoundaryCreationState } from 'src/hooks/boundaryCreation/useBoundaryCreationManger';

import {
    START_ANALYTICAL_MODEL_CREATION_MESSAGE,
    ANALYTICAL_MODEL_CREATION_SUCCESS_MESSAGE,
    ANALYTICAL_MODEL_CREATION_ERROR_MESSAGE,
    ANALYTICAL_MODEL_CREATION_ERROR_TITLE,
} from './BoundaryCreationDialog.constants';

enum InternalState {
    IN_PROGRESS,
    SUCCESS,
    ERROR,
}

// All of these statuses are just guesses. Clipping/Boundary creation is fast but the
// most work. Aggregate initialization is very fast. Project upload can be slow as we
// rely on the file service.
const stateToProgress = {
    [BoundaryCreationState.STARTED]: 0,
    [BoundaryCreationState.ANALYTICAL_BOUNDARY_CREATED]: 45,
    [BoundaryCreationState.AGGREGATE_GEOMETRY_INITIALIZED]: 60,
    [BoundaryCreationState.PROJECT_UPLOADED]: 100,
    [BoundaryCreationState.SUCCESS]: 100,
    [BoundaryCreationState.ERROR]: 100,
};

const stateToInternalState = {
    [BoundaryCreationState.STARTED]: InternalState.IN_PROGRESS,
    [BoundaryCreationState.ANALYTICAL_BOUNDARY_CREATED]: InternalState.IN_PROGRESS,
    [BoundaryCreationState.AGGREGATE_GEOMETRY_INITIALIZED]: InternalState.IN_PROGRESS,
    [BoundaryCreationState.PROJECT_UPLOADED]: InternalState.IN_PROGRESS,
    [BoundaryCreationState.SUCCESS]: InternalState.SUCCESS,
    [BoundaryCreationState.ERROR]: InternalState.ERROR,
};

const internalStateToTitle = {
    [InternalState.IN_PROGRESS]: START_ANALYTICAL_MODEL_CREATION_MESSAGE,
    [InternalState.SUCCESS]: ANALYTICAL_MODEL_CREATION_SUCCESS_MESSAGE,
    [InternalState.ERROR]: ANALYTICAL_MODEL_CREATION_ERROR_TITLE,
};

const internalStateToMessage = {
    [InternalState.IN_PROGRESS]: undefined,
    [InternalState.SUCCESS]: undefined,
    [InternalState.ERROR]: ANALYTICAL_MODEL_CREATION_ERROR_MESSAGE,
};

const internalStateToIcon = {
    [InternalState.IN_PROGRESS]: <BuildCircleOutlinedIcon color="primary" fontSize="large" />,
    [InternalState.SUCCESS]: <CheckCircle color="success" fontSize="large" />,
    [InternalState.ERROR]: <WarningIcon color="error" fontSize="large" />,
};

export interface BoundaryCreationModalProps {
    state: BoundaryCreationState | undefined;
    onRetry: () => void;
    onClose: () => void;
}

export function BoundaryCreationModal({
    state,
    onRetry,
    onClose,
}: Readonly<BoundaryCreationModalProps>) {
    const internalState = state !== undefined ? stateToInternalState[state] : undefined;
    const modalTitle = internalState !== undefined ? internalStateToTitle[internalState] : '';
    const modalMessage =
        internalState !== undefined ? internalStateToMessage[internalState] : undefined;
    const modalIcon = internalState !== undefined ? internalStateToIcon[internalState] : undefined;

    const progressBar =
        internalState !== InternalState.ERROR ? (
            <PercentProgress percent={state ? stateToProgress[state] : 0} showPercentText />
        ) : undefined;

    return (
        <ActionDialog
            open={state !== undefined}
            title={modalTitle}
            message={modalMessage}
            icon={modalIcon}
            actions={
                state !== undefined &&
                internalState === InternalState.ERROR && (
                    <CloseRetryActions onClose={onClose} onRetry={onRetry} />
                )
            }
            progressBar={progressBar}
        />
    );
}
