export const DEFECT_PREFIX = 'defect';
export const HIGHLIGHT_SUFFIX = 'highlight';
export const ELEMENT_SUFFIX = 'element';

export function toDefectViewId(
    objectId: string,
    defectName: string,
    defectIdentifier: number | string,
) {
    return `${DEFECT_PREFIX}-${objectId}-${defectName}-${defectIdentifier}`;
}

export function toHighlightViewId(
    objectId: string,
    defectName: string,
    defectIdentifier: number | string,
) {
    return `${toDefectViewId(objectId, defectName, defectIdentifier)}-${HIGHLIGHT_SUFFIX}`;
}

export function toElementViewId(
    objectId: string,
    defectName: string,
    defectIdentifier: number | string,
) {
    return `${toDefectViewId(objectId, defectName, defectIdentifier)}-${ELEMENT_SUFFIX}`;
}
