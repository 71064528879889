import {
    GtmMeshTransformationAction,
    GtmMeshTransformationParams,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { appendHistoryEntry } from 'src/store/project/projectSlice';
import { selectCurrentProjectVersionId } from 'src/store/project/selectors';
import { useAppSelector, useAppDispatch } from 'src/store/store';
import { makeHistoryEntry } from 'src/utils/history/history';
import { summarizeTransformationActionHistoryOperation } from 'src/utils/history/historySummary';

export function useHistoryManger() {
    const dispatch = useAppDispatch();
    const currentProjectVersionId = useAppSelector(selectCurrentProjectVersionId);

    function addSummarizedHistoryEntry(
        transformationAction: GtmMeshTransformationAction,
        transformationParams: GtmMeshTransformationParams,
    ) {
        dispatch(
            appendHistoryEntry(
                makeHistoryEntry(
                    summarizeTransformationActionHistoryOperation(
                        transformationAction,
                        transformationParams,
                    ),
                    currentProjectVersionId,
                ),
            ),
        );
    }

    return {
        addSummarizedHistoryEntry,
    };
}
