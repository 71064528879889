import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { DEFAULT_PANEL_WIDTH_PX } from 'src/styles';

const styles = (theme: Theme) =>
    ({
        root: {
            width: DEFAULT_PANEL_WIDTH_PX,
            position: 'absolute',
            right: '16px',
            top: '500px',
            zIndex: 2,
        },
        crossSectionTitle: {
            padding: theme.spacing(2),
        },
        inputSection: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            gap: theme.spacing(1.5),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
