import { createSelector } from '@reduxjs/toolkit';

import { defaultAnalyticalModelSettings } from 'src/apiClients/gtmCompute/gtmComputeApi';
import { GtmAnalyticalModelSettings } from 'src/gtmProject';
import { RootState } from 'src/store/store';
import { RemeshSettingsState } from 'src/store/ui/remeshSettings/remeshSettings.types';

export function remeshSettingsFromAnalyticalModelSettings(
    analyticalModelSettings: GtmAnalyticalModelSettings,
): RemeshSettingsState {
    return {
        patchAngleTolerance: analyticalModelSettings.remeshSettings.patchAngleTolerance.toString(),
        patchAngleToleranceValid: true,
        maxChordalError: analyticalModelSettings.remeshSettings.maxChordalError.toString(),
        maxChordalErrorValid: true,
        targetElementSize: analyticalModelSettings.remeshSettings.targetH.toString(),
        targetElementSizeValid: true,
        shapeQualityWeight: analyticalModelSettings.remeshSettings.shapeQualityWeight.toString(),
        shapeQualityWeightValid: true,
    };
}

export function analyticalModelSettingsFromRemeshSettings(
    existingAnalyticalModelSettings: GtmAnalyticalModelSettings,
    remeshSettings: RemeshSettingsState,
): GtmAnalyticalModelSettings {
    return {
        ...existingAnalyticalModelSettings,
        remeshSettings: {
            patchAngleTolerance: Number(remeshSettings.patchAngleTolerance),
            maxChordalError: Number(remeshSettings.maxChordalError),
            targetH: Number(remeshSettings.targetElementSize),
            shapeQualityWeight: Number(remeshSettings.shapeQualityWeight),
        },
    };
}

export const initialRemeshSettingsState = remeshSettingsFromAnalyticalModelSettings(
    defaultAnalyticalModelSettings,
);

type SelectorTypeAll = (state: RootState) => RemeshSettingsState;
type SelectorTypeString = (state: RootState) => string;
type SelectorTypeBoolean = (state: RootState) => boolean;

const remeshSettingsState = (state: RootState): RemeshSettingsState => state.remeshSettings;

export const selectAllRemeshSettings: SelectorTypeAll = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot,
);

export const selectPatchAngleTolerance: SelectorTypeString = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.patchAngleTolerance,
);

export const selectPatchAngleToleranceValid: SelectorTypeBoolean = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.patchAngleToleranceValid,
);

export const selectMaxChordalError: SelectorTypeString = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.maxChordalError,
);

export const selectMaxChordalErrorValid: SelectorTypeBoolean = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.maxChordalErrorValid,
);

export const selectTargetElementSize: SelectorTypeString = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.targetElementSize,
);

export const selectTargetElementSizeValid: SelectorTypeBoolean = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.targetElementSizeValid,
);

export const selectShapeQualityWeight: SelectorTypeString = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.shapeQualityWeight,
);

export const selectShapeQualityWeightValid: SelectorTypeBoolean = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.shapeQualityWeightValid,
);
