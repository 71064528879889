import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useListFilesQuery } from 'src/apiClients/file/enhancedFileMiddleware';
import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { useProjectManager } from 'src/hooks/project';
import { useAppDispatch } from 'src/store/store';
import { setShouldOpenNoProjectDialog, setShouldShowProjectPanel } from 'src/store/ui/projectPanel';
import { PROJECT_EXTENSION } from 'src/strings';
import { fileNameExtensionRemover } from 'src/utils';

export const useProjectDataHandler = () => {
    const dispatch = useAppDispatch();
    const { navigateToProjectURL } = useNavigateToProject();
    const { orgUuid, workspaceUuid, projectName: urlProjectName } = useParams();
    const previousWorkspaceUuidAndProjectName = useRef<string | null>(null);
    const { switchCurrentModel, loadProject } = useConglomerateActionManager();
    const [projectNames, setProjectNames] = useState<string[]>([]);
    const { data: projectFilesData, isFetching: areProjectFilesLoading } = useListFilesQuery({
        organisationId: orgUuid ?? '',
        workspaceId: workspaceUuid ?? '',
        fileName: `.${PROJECT_EXTENSION}`,
    });
    const { fetchProjectFile } = useProjectManager();

    useEffect(() => {
        if (!projectFilesData || areProjectFilesLoading) {
            return;
        }

        const { files } = projectFilesData;

        if (files.length === 0) {
            dispatch(setShouldOpenNoProjectDialog(true));
            dispatch(setShouldShowProjectPanel(false));
        } else {
            const newProjectNames = [...files]
                .sort((file1, file2) => Date.parse(file2.created_at) - Date.parse(file1.created_at))
                .map((file) => fileNameExtensionRemover(file.name));
            setProjectNames(newProjectNames);
            if (!urlProjectName) {
                navigateToProjectURL(newProjectNames[0]);
            }
        }
    }, [projectFilesData, areProjectFilesLoading]);

    useEffect(() => {
        async function loadProjectDataOnProjectNameChange() {
            await fetchProjectFile(urlProjectName as string)
                .then((project) => {
                    if (project) {
                        loadProject(project);
                        if (project.models.length > 0) {
                            switchCurrentModel(0);
                        }
                    }
                })
                .catch((_e) => navigateToProjectURL(projectNames[0]));
        }

        if (projectNames.length === 0) {
            return;
        }

        const newWorkspaceUuidAndProjectName = `${workspaceUuid}/${urlProjectName}`;
        if (previousWorkspaceUuidAndProjectName.current !== newWorkspaceUuidAndProjectName) {
            if (urlProjectName) {
                previousWorkspaceUuidAndProjectName.current = newWorkspaceUuidAndProjectName;
                loadProjectDataOnProjectNameChange();
            }

            // Same workspace selected again without selecting a project, navigate to first project
            const previousWorkspaceUuid =
                previousWorkspaceUuidAndProjectName.current?.split('/')[0];
            if (!urlProjectName && workspaceUuid === previousWorkspaceUuid) {
                navigateToProjectURL(projectNames[0]);
            }
        }
    }, [urlProjectName, workspaceUuid, projectNames]);

    return { projectNames };
};

export const useNavigateToProject = () => {
    const navigate = useNavigate();
    const { orgUuid, workspaceUuid, hubCode } = useParams();

    const navigateToProjectURL = useCallback(
        (navigateProjectName: string | undefined) => {
            const projectURL = navigateProjectName ? `project/${navigateProjectName}` : '';
            navigate(`/${orgUuid}/hub/${hubCode}/workspace/${workspaceUuid}/${projectURL}`);
        },
        [orgUuid, hubCode, workspaceUuid, navigate],
    );

    return { navigateToProjectURL };
};
