// These must match the possible values defined by the Task API
export enum JobStatus {
    Requested = 'requested',
    InProgress = 'in progress',
    Cancelling = 'cancelling',
    Succeeded = 'succeeded',
    Failed = 'failed',
    Cancelled = 'cancelled',
}

export interface TaskStatusResponse {
    status: JobStatus;
    progress: number;
    message: string;
    error: string | null;
    links: {
        [key: string]: string;
    };
}

export interface TaskResult {
    status: JobStatus;
    error: string | null;
    results: any;
}

export interface ComputeTaskQuery<T> {
    orgId: string;
    parameters: T;
}
