// Errors
export const ERROR_BOUNDARY_MESSAGE = 'Something has gone wrong.';
export const ERROR_BOUNDARY_DETAILS =
    'This has been logged and we will investigate what happened. Please refresh your browser to continue.';

// 404 page
export const NOT_FOUND_TITLE = 'Page not found';
export const NOT_FOUND_MESSAGE = 'Double check the URL or try again later';

// Visualization page
export const PLOT = 'Plot';

// Defect types
export const DEGENERATE_TRIANGLE = 'Degen. Triangle';
export const DUPLICATE_POINT = 'Duplicate Point';
export const DUPLICATE_TRIANGLE = 'Duplicate Triangle';
export const FIN = 'Fin';
export const HOLE = 'Hole';
export const SELF_INTERSECTION = 'Self-intersection';
export const NON_MANIFOLD_EDGE = 'Non-manifold edge';
export const NON_MANIFOLD_VERTEX = 'Non-manifold vertex';
export const INCONSISTENT_TRIANGLE = 'Inconsistent triangle';
export const REDUNDANT_SURFACE = 'Non-partitioning surface';
export const UNKNOWN_DEFECT_TYPE = 'Unknown Defect Type';

// Creating a project
export const ERROR_CREATING_PROJECT = 'Error creating the boundary file';
export const PROJECT_EXTENSION = 'gtm';
export const INTERNAL_OBJECT_FOLDER = `_${PROJECT_EXTENSION}`;

export const ERROR_UPLOADING_PROJECT =
    'Error uploading the project. The cloud project is not synchronized until the next successful operation.';

// Generic buttons/actions
export const CANCEL_LABEL = 'Cancel';
export const RESET_LABEL = 'Reset';
export const SAVE_LABEL = 'Save';
