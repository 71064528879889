import { fileNameExtensionRemover } from 'src/utils';
import { getNextSequentialName } from 'src/utils/stringHelpers';
import {
    FILE_SIZE_LIMIT_BYTES,
    getInvalidFileSizeMessage,
    getInvalidFileTypeMessage,
} from 'src/visualization/ProjectPanel/components/ObjectsPanel/UploadObjectsDialog/UploadObjectsDialog.constants';

function validateFileSize(file: File) {
    if (file.size > FILE_SIZE_LIMIT_BYTES) {
        return getInvalidFileSizeMessage(file.name);
    }
    return null;
}

function validateFileType(file: File) {
    const fileExtension = file.name?.split('.')?.pop() ?? '';
    if (fileExtension.toLowerCase() !== 'obj') {
        return getInvalidFileTypeMessage(file.name);
    }
    return null;
}

export function validateFiles(files: File[]): [files: File[], errors: string[]] {
    const errors: string[] = [];
    const validatedFiles = files.filter((file) => {
        const fileSizeError = validateFileSize(file);
        if (fileSizeError) {
            errors.push(fileSizeError);
            return false;
        }
        const fileTypeError = validateFileType(file);
        if (fileTypeError) {
            errors.push(fileTypeError);
            return false;
        }
        return true;
    });
    return [validatedFiles, errors];
}

export function handleDuplicateFileNames(files: File[], existingNames: string[]) {
    return files.map((file) => {
        const fileNameStem = fileNameExtensionRemover(file.name);
        const nextSequentialName = getNextSequentialName(fileNameStem, existingNames);
        if (fileNameStem === nextSequentialName) {
            return file;
        }
        return new File([file], `${nextSequentialName}.obj`, { type: file.type });
    });
}
