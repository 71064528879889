import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';

import { GtmProject } from 'src/gtmProject';
import { useLazyUploadFile } from 'src/hooks/evoContext/useLazyUploadFile';
import { createFileFromProject } from 'src/hooks/utils';
import { setCurrentProjectVersionId } from 'src/store/project/projectSlice';
import { useAppDispatch, store } from 'src/store/store';
import { ERROR_UPLOADING_PROJECT } from 'src/strings';

// The purpose of this hook is to dump the state of the project slice of the store to the file service.
// This pattern exists to ensure consistency between the application state and what we persist to file.
//
// This pattern facilitates a concern: What if the upload to the file service fails?
//    This would cause there to be a period of time where the application state and the persisted state are out of sync.
//    If a user were to refresh the page during this time, they would lose their work.
//    We have been referring to this pattern as "optimistic update" - "optimistic" because we assume the upload will succeed.
//    Conversely, a "pessimistic update" would be to wait for the upload to succeed before updating the application state,
//    and would prevent the application from updating if the upload failed. The rationale for the optimistic approach is that
//    it's a better user experience to allow the user to continue working, even if the upload fails, as we can retry the upload
//    in the background or ask the user to retry later. The assumption, again, is that upload failures are rare.
//
//
// Call `syncProject` whenever a state change or batch of state changes should be persisted.
export function useProjectSynchronizer() {
    const [UploadFileTrigger] = useLazyUploadFile();
    const dispatch = useAppDispatch();
    const { addMessage } = useMessagesContext();

    return {
        syncProject: async () => {
            // Calling store.getState() (as opposed to pulling the state from a selector) ensures
            // that the latest state is used, even if the state has changed since the
            // function was created (i.e. agnostic of the react render loop).
            const currentProject: GtmProject = store.getState().project.current.project;

            const updatedFile = createFileFromProject(currentProject);

            return UploadFileTrigger(updatedFile)
                .then((response) => {
                    if (!response.data) {
                        throw new Error('No data returned from upload');
                    }
                    dispatch(setCurrentProjectVersionId(response.data.version_id));
                })
                .catch((error) => {
                    addMessage({
                        message: ERROR_UPLOADING_PROJECT,
                        type: NotificationType.ERROR,
                    });
                    trackError(
                        `Error: ${error} uploading new version of project "${updatedFile.name}"`,
                    );
                    return Promise.reject();
                });
        },
    };
}
