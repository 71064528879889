import {
    UpdateGeoscienceObject,
    useUpdateObjectsByIdMutation,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { GetObjectResponse } from '@local/api-clients/src/goose/GENERATED_gooseClientEndpoints';

import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { useAppDispatch } from 'src/store/store';
import { addOrUpdateSceneObject } from 'src/store/visualization/visualizationSlice';
import { ObjectId, VersionId } from 'src/types/core.types';

import { useObjectManager } from '../project/useObjectManager';

export function useObjectNonGeometryUpdate() {
    const dispatch = useAppDispatch();
    const [UpdateObjectTrigger, { isLoading }] = useUpdateObjectsByIdMutation();
    const { findObjectAndSetVersion } = useObjectManager();
    const { syncProject } = useProjectSynchronizer();
    const gooseContext = useGooseContext();

    // Updates an object in goose and the store.
    // NOTE: Do NOT use this to modify geometry. Go through transformations in compute tasks for that
    // so we can re-run detectors, trace the change properly, get the log, etc.
    async function objectNonGeometryUpdate(newObject: GetObjectResponse) {
        const updatedObject: UpdateGeoscienceObject = {
            ...newObject.object,
            schema: newObject.object.schema,
            uuid: newObject.object_id,
        };

        const res = await UpdateObjectTrigger({
            objectId: newObject.object_id,
            orgId: gooseContext!.orgId,
            workspaceId: gooseContext!.workspaceId,
            geoscienceObject: updatedObject,
        }).unwrap();
        if (!res || !res.version_id) throw new Error('Error updating object');

        findObjectAndSetVersion(newObject.object_id as ObjectId, res.version_id as VersionId);

        // Actual type is PostObjectResponse but they're almost the same. Equivalent for  what we need.
        const getObjectResponse = res as GetObjectResponse;
        dispatch(
            addOrUpdateSceneObject([
                newObject.object_id,
                {
                    gooseObject: getObjectResponse,
                    version: res.version_id as VersionId,
                },
            ]),
        );

        // Optimistic update - no await
        syncProject();
    }

    return { objectNonGeometryUpdate, isLoading };
}
