import { WDSThemeProvider } from '@local/web-design-system-2';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useContext, useState } from 'react';

import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { selectDoesCurrentModelSelectedObjectHaveIssues } from 'src/store/issues/selectors';
import {
    selectCurrentModelSelectedObject,
    selectIsAggregateObjectSelected,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import {
    sceneObjectById,
    selectNumOfTrianglesForCurrentModelSelectedObject,
    selectNumOfVerticesForCurrentModelSelectedObject,
} from 'src/store/visualization/selectors';
import { DEFAULT_PANEL_WIDTH } from 'src/styles';
import { fileNameExtensionRemover } from 'src/utils';
import { IssuesTab } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab';
import { VolumesPanel } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/VolumesPanel';
import {
    ISSUES_LABEL,
    APPEARANCE_LABEL,
    TRIANGLES_LABEL,
    VERTICES_LABEL,
} from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/ObjectsSettingsPanel.constants';

import { GeometryAppearancePanel } from './components/AppearanceTab/GeometryAppearancePanel';

const enum ObjectSettingsPanelTabSelection {
    Issues,
    Appearance,
}

interface ObjectSettingsPanelProps {
    sx?: SxProps<Theme>;
}

export function ObjectsSettingsPanel({ sx }: ObjectSettingsPanelProps) {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const currentModelSelectedObject = useAppSelector(selectCurrentModelSelectedObject);
    const doesCurrentModelSelectedObjectHaveIssues = useAppSelector(
        selectDoesCurrentModelSelectedObjectHaveIssues,
    );
    const isAggregateObjectSelected = useAppSelector(selectIsAggregateObjectSelected);
    const numOfTriangles = useAppSelector(selectNumOfTrianglesForCurrentModelSelectedObject);
    const numOfVertices = useAppSelector(selectNumOfVerticesForCurrentModelSelectedObject);
    const [tabSelection, setTabSelection] = useState(ObjectSettingsPanelTabSelection.Issues);
    const applyTrace = useTrace('object-settings-panel');

    const handleTabSelection = (_e: unknown, newValue: ObjectSettingsPanelTabSelection) => {
        setTabSelection(newValue);
    };

    // Disable the appearance tab if the object is not loaded. This may change in the future, but for now, we don't
    // have the goose object to change appearance settings within if the object is not loaded.
    const sceneObject = useAppSelector(sceneObjectById(currentModelSelectedObject!.id));
    const isModelObjectLoaded: boolean =
        sceneObject !== undefined && sceneObject.gooseObject !== undefined;

    return (
        <Box sx={sx}>
            <WDSThemeProvider themeMode={appTheme}>
                <Stack spacing={2}>
                    <Paper sx={{ width: DEFAULT_PANEL_WIDTH }} elevation={4}>
                        <Stack p={2}>
                            <Typography>
                                {fileNameExtensionRemover(currentModelSelectedObject?.name)}
                            </Typography>
                            <Stack direction="row" spacing={1}>
                                <Typography
                                    color="secondary"
                                    variant="body2"
                                >{`${TRIANGLES_LABEL}: ${numOfTriangles}`}</Typography>
                                <Typography
                                    color="secondary"
                                    variant="body2"
                                >{`${VERTICES_LABEL}: ${numOfVertices}`}</Typography>
                            </Stack>
                        </Stack>
                        <Tabs
                            sx={(theme) => ({
                                borderBottom: `${theme.palette.divider} 1px solid`,
                            })}
                            value={tabSelection}
                            onChange={handleTabSelection}
                        >
                            <Tab
                                label={
                                    <Badge
                                        sx={(theme) => ({
                                            '.MuiBadge-badge': {
                                                transform: `translate(${theme.spacing(2)}, -${theme.spacing(1)})`,
                                            },
                                        })}
                                        color="warning"
                                        variant="dot"
                                        invisible={!doesCurrentModelSelectedObjectHaveIssues}
                                    >
                                        <Typography
                                            variant="button"
                                            sx={{ textTransform: 'capitalize' }}
                                        >
                                            {ISSUES_LABEL}
                                        </Typography>
                                    </Badge>
                                }
                                value={ObjectSettingsPanelTabSelection.Issues}
                                automation-id={applyTrace('issues-tab')}
                            />
                            <Tab
                                label={
                                    <Typography
                                        variant="button"
                                        sx={{ textTransform: 'capitalize' }}
                                    >
                                        {APPEARANCE_LABEL}
                                    </Typography>
                                }
                                value={ObjectSettingsPanelTabSelection.Appearance}
                                automation-id={applyTrace('appearance-tab')}
                                disabled={!isModelObjectLoaded}
                            />
                        </Tabs>
                        {tabSelection === ObjectSettingsPanelTabSelection.Issues && <IssuesTab />}
                        {tabSelection === ObjectSettingsPanelTabSelection.Appearance &&
                            isModelObjectLoaded && <GeometryAppearancePanel />}
                    </Paper>
                    {isAggregateObjectSelected && <VolumesPanel />}
                </Stack>
            </WDSThemeProvider>
        </Box>
    );
}
