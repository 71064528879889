import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { getConfig, ProvideConfig } from '@local/app-config';
import { ErrorBoundary } from '@local/error-logging';
import { MessagesProvider } from '@local/messages/dist/MessagesContext';
import { MetricsWrapper, trackError } from '@local/metrics';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import { StyledEngineProvider } from '@mui/material/styles';
import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { TssCacheProvider } from 'tss-react';

import { version } from '../package.json';
import { App } from './App';
import { store } from './store/store';
import { ERROR_BOUNDARY_DETAILS, ERROR_BOUNDARY_MESSAGE } from './strings';

const muiCache = createCache({
    key: 'mui',
    prepend: true,
});

const tssCache = createCache({
    key: 'tss',
});

const errorScreen = () => (
    <ErrorScreen msg={ERROR_BOUNDARY_MESSAGE} details={ERROR_BOUNDARY_DETAILS} />
);

let ldClient: LDClient | null = null;

function LaunchDarklyProviderWrapper({ children }: { children: JSX.Element }) {
    const [loaded, setLoaded] = useState(false);
    const config = getConfig();
    // Only initialize the client if the global client is null - ie it hasn't been loaded before.
    if (ldClient === null) {
        ldClient = initialize(config.launchDarklyClientID, { anonymous: true });
    }

    useEffect(() => {
        if (ldClient !== null) {
            ldClient
                .waitForInitialization()
                .catch((reason) => {
                    trackError(reason, 'Error initialising LD client, rendering defaults');
                })
                .finally(() => setLoaded(true));
        }
    }, []);

    if (loaded) {
        const LDProvider = withLDProvider({ clientSideID: config.launchDarklyClientID, ldClient })(
            () => children,
        );
        return <LDProvider />;
    }

    return null;
}

// We only send data to sentry and datadog for release builds (NODE_ENV production), not dev builds.
const recordMetrics = process.env.NODE_ENV === 'production';

// Taken from https://seequent-ltd.sentry.io/settings/projects/geotechnical-modeler/keys/
const sentryDsn =
    'https://c7e02dbba2566385b75361362769691c@o189631.ingest.us.sentry.io/4507250858459136';

// Taken from https://app.datadoghq.com/rum/application/542bad21-4ce9-4d2b-8563-2beb83b48111/
const datadogConfig = {
    appId: '542bad21-4ce9-4d2b-8563-2beb83b48111',
    clientToken: 'pub622a3461ca3dee208c0378c7c7108acc',
    service: 'geotechnical-modeler',
    version,
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <IntlProvider locale="en">
            <ProvideConfig>
                <CacheProvider value={muiCache}>
                    <TssCacheProvider value={tssCache}>
                        <StyledEngineProvider>
                            <ErrorBoundary
                                errorComponent={errorScreen()}
                                sentryDSN={recordMetrics ? sentryDsn : undefined}
                            >
                                <MetricsWrapper
                                    datadogConfig={recordMetrics ? datadogConfig : undefined}
                                >
                                    <Provider store={store}>
                                        <MessagesProvider>
                                            <BrowserRouter>
                                                <LaunchDarklyProviderWrapper>
                                                    <App />
                                                </LaunchDarklyProviderWrapper>
                                            </BrowserRouter>
                                        </MessagesProvider>
                                    </Provider>
                                </MetricsWrapper>
                            </ErrorBoundary>
                        </StyledEngineProvider>
                    </TssCacheProvider>
                </CacheProvider>
            </ProvideConfig>
        </IntlProvider>
    </React.StrictMode>,
);
