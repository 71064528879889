export const getFillAllHolesLabel = (numOfHoles: number) => `Fill all holes (${numOfHoles})`;
export const FILLING_HOLES_MESSAGE = 'Filling holes...';
export const FILLING_HOLES_SUCCESS_MESSAGE = 'Holes filled successfully';
export const FILLING_HOLES_FAILURE_MESSAGE = 'Failed to fill holes';
export const FILL_LABEL = 'Fill';
export const FILL_AND_REFINE_LABEL = 'Fill and refine';
export const FILL_INFO = 'Fill all holes.';
export const FILL_AND_REFINE_INFO =
    'Will try to make the fill C1 continuous with the hole boundary.';

export const FIX_DEGENERATE_TRIANGLES_LABEL = 'Fix Degenerate Triangles';
export const FIXING_DEGENERATE_TRIANGLES_MESSAGE = 'Fixing degenerate triangles...';
export const FIXING_DEGENERATE_TRIANGLES_SUCCESS_MESSAGE =
    'Degenerate triangles fixed successfully';
export const FIXING_DEGENERATE_TRIANGLES_FAILURE_MESSAGE = 'Failed to fix degenerate triangles';

export const FIX_SELF_INTERSECTIONS_LABEL = 'Fix Self-Intersections';
export const FIX_SELF_INTERSECTIONS_MESSAGE = 'Fixing self-intersections...';
export const FIX_SELF_INTERSECTIONS_SUCCESS_MESSAGE = 'Self-intersections fixed successfully';
export const FIX_SELF_INTERSECTIONS_FAILURE_MESSAGE = 'Failed to fix self-intersections';
