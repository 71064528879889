import { useParams } from 'react-router-dom';

import { useDeleteFileByPathMutation } from 'src/apiClients/file/enhancedFileMiddleware';

export function useDeleteFile() {
    const [DeleteFileByPathTrigger] = useDeleteFileByPathMutation();
    const { orgUuid, workspaceUuid } = useParams();

    async function DeleteFileTrigger(filePath: string) {
        return DeleteFileByPathTrigger({
            organisationId: orgUuid!,
            workspaceId: workspaceUuid!,
            filePath,
        });
    }

    return [DeleteFileTrigger];
}
