import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { DEFAULT_PANEL_WIDTH_PX } from 'src/styles';

const styles = (theme: Theme) =>
    ({
        root: {
            width: DEFAULT_PANEL_WIDTH_PX,
            position: 'absolute',
            right: '16px',
            top: '16px',
            zIndex: 2,
        },
        settingsSection: {
            padding: theme.spacing(2),
            gap: theme.spacing(0.5),
        },
        colorTextFieldInput: {
            fontSize: theme.typography.body2.fontSize,
        },
        axisToggle: {
            padding: theme.spacing(0.75),
        },
        propertyInput: {
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
