import { gooseClient as api } from './gooseClient';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        listObjectsByOrg: build.query<ListObjectsByOrgApiResponse, ListObjectsByOrgApiArg>({
            query: (queryArg) => ({
                url: `/geoscience-object/orgs/${queryArg.orgId}/objects`,
                params: {
                    offset: queryArg.offset,
                    limit: queryArg.limit,
                    deleted: queryArg.deleted,
                    permitted_workspaces_only: queryArg.permittedWorkspacesOnly,
                    order_by: queryArg.orderBy,
                    created_by: queryArg.createdBy,
                    created_at: queryArg.createdAt,
                    modified_by: queryArg.modifiedBy,
                    modified_at: queryArg.modifiedAt,
                    object_name: queryArg.objectName,
                    schema_id: queryArg.schemaId,
                    geojson_bounding_box: queryArg.geojsonBoundingBox,
                },
            }),
        }),
        updateObjectsById: build.mutation<UpdateObjectsByIdApiResponse, UpdateObjectsByIdApiArg>({
            query: (queryArg) => ({
                url: `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects/${queryArg.objectId}`,
                method: 'POST',
                body: queryArg.geoscienceObject,
                headers: { 'If-Match': queryArg['If-Match'] ?? undefined },
                params: { deleted: queryArg.deleted },
            }),
        }),
        getObjectById: build.query<GetObjectByIdApiResponse, GetObjectByIdApiArg>({
            query: (queryArg) => ({
                url: `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects/${queryArg.objectId}`,
                headers: { 'If-None-Match': queryArg['If-None-Match'] ?? undefined },
                params: {
                    version: queryArg.version,
                    include_versions: queryArg.includeVersions,
                    deleted: queryArg.deleted,
                },
            }),
        }),
        deleteObjectsById: build.mutation<DeleteObjectsByIdApiResponse, DeleteObjectsByIdApiArg>({
            query: (queryArg) => ({
                url: `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects/${queryArg.objectId}`,
                method: 'DELETE',
            }),
        }),
        listObjects: build.query<ListObjectsApiResponse, ListObjectsApiArg>({
            query: (queryArg) => ({
                url: `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    deleted: queryArg.deleted,
                    order_by: queryArg.orderBy,
                    author: queryArg.author,
                    created_by: queryArg.createdBy,
                    created_at: queryArg.createdAt,
                    modified_by: queryArg.modifiedBy,
                    modified_at: queryArg.modifiedAt,
                    object_name: queryArg.objectName,
                    schema_id: queryArg.schemaId,
                    geojson_bounding_box: queryArg.geojsonBoundingBox,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as gooseClientEndpoints };
export type ListObjectsByOrgApiResponse =
    /** status 200 Successful Response */ ListOrgObjectsResponse;
export type ListObjectsByOrgApiArg = {
    /** The customer's organisation ID */
    orgId: string;
    /** Number of results to skip before returning `limit` number of results. */
    offset?: number;
    /** Max number of results per page. */
    limit?: number;
    /** When true, only objects that have been deleted will be returned */
    deleted?: boolean;
    /** Only return objects in workspaces the user is permitted to access. */
    permittedWorkspacesOnly?: boolean;
    /** A comma separated list of fields to order by, where the default sort order is ascending. To specify the sort order, prefix the field name with either `asc:` or `desc:` for ascending or descending respectively. Field names can either be arbitrary, representing a field nested inside the data, or one of the following known sort fields: `created_at`, `created_by`, `modified_at`, `modified_by`, `object_name` */
    orderBy?: string;
    /** The UUID of the user that created an object */
    createdBy?: (string | string)[];
    /**
    A date or dates (max 2) to filter objects by. Dates may contain operator prefixes,
    in the form `<operator>:<datetime>`. The following operators are available
    (`lt`=less than, `lte`=less than or equal to, `gt`=greater than, `gte`=greater than or equal to).
    If you omit the operator, then it is assumed the operator is 'equal to'.
    In this case you may only supply one date. The dates must also be in a valid ISO 8601 format.
    Dates may include a UTC offset. If the offset is omitted, the timezone is assumed to be UTC.
     */
    createdAt?: string[];
    /** The UUID of the user that modified an object */
    modifiedBy?: (string | string)[];
    /**
    A date or dates (max 2) to filter objects by. Dates may contain operator prefixes,
    in the form `<operator>:<datetime>`. The following operators are available
    (`lt`=less than, `lte`=less than or equal to, `gt`=greater than, `gte`=greater than or equal to).
    If you omit the operator, then it is assumed the operator is 'equal to'.
    In this case you may only supply one date. The dates must also be in a valid ISO 8601 format.
    Dates may include a UTC offset. If the offset is omitted, the timezone is assumed to be UTC.
     */
    modifiedAt?: string[];
    /** The name of the object to filter on. For backwards compatibility, when no operators are supplied, this will perform a case sensitive prefix match of the object file name. The query `object_name=gold` will match an object with the name `goldcolumns.json`. Providing an operator will query over the entire path to and including the file name. */
    objectName?: string[];
    /** The type of schema you want to filter by. */
    schemaId?: string[];
    /** The coordinates of the data to spatially search on. The value may optionally include one of two spatial operators, `geowithin` or `geointersects`. If an operator is not defined, then `geointersects` will be used by default. The coordinates must be 5 pairs of longitude and latitude, representing a closed polygon. The first and last coordinates must be the same to close the polygon. */
    geojsonBoundingBox?: string[];
};
export type UpdateObjectsByIdApiResponse =
    /** status 201 The geoscience object version has been created. */ PostObjectResponse;
export type UpdateObjectsByIdApiArg = {
    /** UUID of a geoscience object. */
    objectId: string;
    /** The customer's organisation ID */
    orgId: string;
    workspaceId: string;
    /** When true, only objects that have been deleted will be returned */
    deleted?: boolean;
    /** Optional header to apply an update _only if_ the specified object version is the most recent available on the server. */
    'If-Match'?: string | null;
    geoscienceObject: UpdateGeoscienceObject | null;
};
export type GetObjectByIdApiResponse =
    /** status 200 The requested geoscience object and metadata */ GetObjectResponse;
export type GetObjectByIdApiArg = {
    /** UUID of a geoscience object. */
    objectId: string;
    /** The customer's organisation ID */
    orgId: string;
    workspaceId: string;
    /** The (timestamp) version of the object to retrieve. Defaults to the latest version if not specified. */
    version?: string;
    /** Whether to return all versions of the object */
    includeVersions?: boolean;
    /** When true, only objects that have been deleted will be returned */
    deleted?: boolean;
    /** Optional header used to check if there are any more recent versions of an object than the one specified. Returns 304 (Not Modified) if there are no newer versions. */
    'If-None-Match'?: string | null;
};
export type DeleteObjectsByIdApiResponse =
    /** status 204 The geoscience object version has been deleted. */ void;
export type DeleteObjectsByIdApiArg = {
    objectId: string;
    /** The customer's organisation ID */
    orgId: string;
    workspaceId: string;
};
export type ListObjectsApiResponse = /** status 200 Successful Response */ ListObjectsResponse;
export type ListObjectsApiArg = {
    /** The customer's organisation ID */
    orgId: string;
    workspaceId: string;
    /** Max number of results per page. */
    limit?: number;
    /** Number of results to skip before returning `limit` number of results. */
    offset?: number;
    /** When true, only objects that have been deleted will be returned */
    deleted?: boolean;
    /** A comma separated list of fields to order by, where the default sort order is ascending. To specify the sort order, prefix the field name with either `asc:` or `desc:` for ascending or descending respectively. Field names can either be arbitrary, representing a field nested inside the data, or one of the following known sort fields: `author`, `created_at`, `created_by`, `modified_at`, `modified_by`, `object_name` */
    orderBy?: string;
    /** (deprecated, use `created_by`) The profile ID that you want to filter by */
    author?: (string | string)[];
    /** The UUID of the user that created an object */
    createdBy?: (string | string)[];
    /**
    A date or dates (max 2) to filter objects by. Dates may contain operator prefixes,
    in the form `<operator>:<datetime>`. The following operators are available
    (`lt`=less than, `lte`=less than or equal to, `gt`=greater than, `gte`=greater than or equal to).
    If you omit the operator, then it is assumed the operator is 'equal to'.
    In this case you may only supply one date. The dates must also be in a valid ISO 8601 format.
    Dates may include a UTC offset. If the offset is omitted, the timezone is assumed to be UTC.
     */
    createdAt?: string[];
    /** The UUID of the user that modified an object */
    modifiedBy?: (string | string)[];
    /**
    A date or dates (max 2) to filter objects by. Dates may contain operator prefixes,
    in the form `<operator>:<datetime>`. The following operators are available
    (`lt`=less than, `lte`=less than or equal to, `gt`=greater than, `gte`=greater than or equal to).
    If you omit the operator, then it is assumed the operator is 'equal to'.
    In this case you may only supply one date. The dates must also be in a valid ISO 8601 format.
    Dates may include a UTC offset. If the offset is omitted, the timezone is assumed to be UTC.
     */
    modifiedAt?: string[];
    /** The name of the object to filter on. For backwards compatibility, when no operators are supplied, this will perform a case sensitive prefix match of the object file name. The query `object_name=gold` will match an object with the name `goldcolumns.json`. Providing an operator will query over the entire path to and including the file name. */
    objectName?: string[];
    /** The type of schema you want to filter by. */
    schemaId?: string[];
    /** The coordinates of the data to spatially search on. The value may optionally include one of two spatial operators, `geowithin` or `geointersects`. If an operator is not defined, then `geointersects` will be used by default. The coordinates must be 5 pairs of longitude and latitude, representing a closed polygon. The first and last coordinates must be the same to close the polygon. */
    geojsonBoundingBox?: string[];
};
export type ListObjectsResponseLinks = {
    next?: string | null;
    prev?: string | null;
};
export type User = {
    email?: string | null;
    id: string;
    name?: string | null;
};
export type GeoJsonType = 'Polygon';
export type BoundingBox = {
    coordinates: number[][][];
    type?: GeoJsonType;
};
export type RoleEnum = 'owner' | 'editor' | 'viewer';
export type OrgListedObject = {
    created_at: string;
    created_by?: User | null;
    geojson_bounding_box?: BoundingBox | null;
    modified_at: string;
    modified_by?: User | null;
    name: string;
    object_id: string;
    schema: string;
    workspace_access?: RoleEnum | null;
    workspace_id: string;
    workspace_name?: string | null;
};
export type ListOrgObjectsResponse = {
    count?: number;
    limit?: number;
    links: ListObjectsResponseLinks;
    objects: OrgListedObject[];
    offset?: number;
    total?: number;
};
export type HttpStatus =
    | 100
    | 101
    | 102
    | 103
    | 200
    | 201
    | 202
    | 203
    | 204
    | 205
    | 206
    | 207
    | 208
    | 226
    | 300
    | 301
    | 302
    | 303
    | 304
    | 305
    | 307
    | 308
    | 400
    | 401
    | 402
    | 403
    | 404
    | 405
    | 406
    | 407
    | 408
    | 409
    | 410
    | 411
    | 412
    | 413
    | 414
    | 415
    | 416
    | 417
    | 418
    | 421
    | 422
    | 423
    | 424
    | 425
    | 426
    | 428
    | 429
    | 431
    | 451
    | 500
    | 501
    | 502
    | 503
    | 504
    | 505
    | 506
    | 507
    | 508
    | 510
    | 511;
export type BadRequestError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type MissingTokenError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type InvalidTokenError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type InvalidFilterParams = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type InvalidSortParams = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type EvoRequestValidationError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ApiError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ObjectStoreError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type BadGateway = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type DataDownloadUrl = {
    download_url?: string | null;
    id: string;
};
export type NamedDataDownloadUrl = {
    download_url?: string | null;
    id: string;
    name: string;
};
export type ObjectResponseLinks = {
    data: (DataDownloadUrl | NamedDataDownloadUrl)[];
    download: string;
};
export type GeoscienceObject = {
    schema: string;
    uuid?: string | null;
    [key: string]: any;
};
export type PostObjectResponse = {
    created_at: string;
    created_by?: User | null;
    etag: string;
    geojson_bounding_box?: BoundingBox | null;
    links: ObjectResponseLinks;
    modified_at: string;
    modified_by?: User | null;
    object: GeoscienceObject;
    object_id?: string | null;
    object_path?: string | null;
    version_id: string;
};
export type InvalidGeoscienceObject = {
    detail?: string | null;
    json_path?: string;
    schema_path?: any[];
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ObjectDataRefInvalid = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ObjectDataRefNotFound = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ObjectDataRefNotMovedError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type InvalidObjectError = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type InsufficientRoleError = {
    detail?: string | null;
    permitted_roles?: any[];
    status?: HttpStatus;
    title?: string;
    type?: string;
    your_role?: string;
};
export type ObjectNotFound = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type SchemaRequestFailed = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ObjectAlreadyExistsError = {
    detail?: string | null;
    existing_id?: string | null;
    object_path?: string;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type ObjectModified = {
    detail?: string | null;
    status?: HttpStatus;
    title?: string;
    type?: string;
};
export type UpdateGeoscienceObject = {
    schema: string;
    uuid: string;
    [key: string]: any;
};
export type ObjectVersionResponseLinks = {
    download: string;
};
export type GeoscienceObjectVersion = {
    created_at: string;
    created_by?: User | null;
    etag: string;
    links: ObjectVersionResponseLinks;
    version_id: string;
};
export type GetObjectResponse = {
    created_at: string;
    created_by?: User | null;
    etag: string;
    geojson_bounding_box?: BoundingBox | null;
    links: ObjectResponseLinks;
    modified_at: string;
    modified_by?: User | null;
    object: GeoscienceObject;
    object_id: string;
    object_path?: string | null;
    version_id: string;
    versions?: GeoscienceObjectVersion[] | null;
};
export type ListedObjectLinks = {
    download: string;
};
export type ListedObject = {
    created_at: string;
    created_by?: User | null;
    etag: string;
    geojson_bounding_box?: BoundingBox | null;
    links: ListedObjectLinks;
    modified_at: string;
    modified_by?: User | null;
    name: string;
    object_id: string;
    path: string;
    schema: string;
    version_id: string;
};
export type ListObjectsResponse = {
    count?: number;
    limit?: number;
    links: ListObjectsResponseLinks;
    objects: ListedObject[];
    offset?: number;
    total?: number;
};
export const {
    useListObjectsByOrgQuery,
    useLazyListObjectsByOrgQuery,
    useUpdateObjectsByIdMutation,
    useGetObjectByIdQuery,
    useLazyGetObjectByIdQuery,
    useDeleteObjectsByIdMutation,
    useListObjectsQuery,
    useLazyListObjectsQuery,
} = injectedRtkApi;
