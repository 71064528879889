import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { SurfaceElementState } from '@local/webviz/dist/types';

import {
    GtmMeshDetectorAction,
    GtmMeshDetectionData,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { detectorActionToDefectString } from 'src/utils/typeTransformations';

import { generateDefectsSnapshotState } from './defectsFactory';
import { generateHighlightSnapshot, HighlightClasses } from './highlightFactory';
import { toDefectViewId, toHighlightViewId } from './uid';

export function useDefectsVisualizationManager() {
    const {
        getEntityState,
        setStateFromSnapshot,
        addViewToPlotDirectly,
        getState,
        removeViewsFromPlotDirectly,
    } = useBaseXyz();

    function removeIssuesForObject(objectId: string, action: GtmMeshDetectorAction | undefined) {
        // The following is an example of an id for an issue view:
        // defects-d4568b22-7a64-4c59-bc6a-85b308b7bf2b-Hole-2
        const regex = new RegExp(
            `^${toDefectViewId(
                objectId,
                action ? detectorActionToDefectString(action) : '.*',
                '.*',
            )}(?!.)`,
        );
        const objects = Object.keys(getState()).filter((key) => regex.test(key));
        removeViewsFromPlotDirectly([...objects]);
    }

    function removeHighlightsForObject(
        objectId: string,
        action: GtmMeshDetectorAction | undefined,
    ) {
        // The following is an example of an id for an issue highlight view:
        // defects-d4568b22-7a64-4c59-bc6a-85b308b7bf2b-Hole-2-highlight
        const regex = new RegExp(
            toHighlightViewId(objectId, action ? detectorActionToDefectString(action) : '.*', '.*'),
        );
        const objects = Object.keys(getState()).filter((key) => regex.test(key));
        removeViewsFromPlotDirectly([...objects]);
    }

    function renderHighlights(
        objectId: string,
        defects: GtmMeshDetectionData,
        action: GtmMeshDetectorAction,
        defectIndex: number,
    ) {
        const surfaceElement = getEntityState(`${objectId}-element`) as SurfaceElementState;
        if (surfaceElement && 'triangles' in surfaceElement && 'vertices' in surfaceElement) {
            const snapshotMap = generateHighlightSnapshot(action as HighlightClasses, {
                objectId,
                defectIndex,
                surface: surfaceElement,
                defects,
            });
            if (snapshotMap) {
                const viewIds = Object.keys(snapshotMap);
                viewIds.forEach((viewId) => {
                    if (snapshotMap[viewId]) {
                        setStateFromSnapshot(snapshotMap[viewId], {});
                        addViewToPlotDirectly(viewId);
                    }
                });
            }
        }
    }

    function renderIssues(
        objectId: string,
        defects: GtmMeshDetectionData,
        action: GtmMeshDetectorAction,
    ) {
        const surfaceElement = getEntityState(`${objectId}-element`) as SurfaceElementState;
        if (surfaceElement && 'triangles' in surfaceElement && 'vertices' in surfaceElement) {
            const snapshotMap = generateDefectsSnapshotState(action, {
                defects,
                surface: surfaceElement,
                objectId,
            });
            if (snapshotMap) {
                const viewIds = Object.keys(snapshotMap);
                viewIds.forEach((viewId) => {
                    if (snapshotMap[viewId]) {
                        setStateFromSnapshot(snapshotMap[viewId], {});
                        addViewToPlotDirectly(viewId);
                    }
                });
            }
        }
    }

    function refreshIssues(
        objectId: string,
        action: GtmMeshDetectorAction,
        defects: GtmMeshDetectionData,
    ) {
        removeIssuesForObject(objectId, action);
        renderIssues(objectId, defects, action);
    }

    return {
        removeIssuesForObject,
        removeHighlightsForObject,
        renderHighlights,
        renderIssues,
        refreshIssues,
    };
}
