import { useState } from 'react';

import {
    defaultAnalyticalModelSettings,
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import {
    GtmMeshTransformationAction,
    GtmMeshTransformationParams,
    GtmMeshTransformationResponse,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmAnalyticalModelSettings } from 'src/gtmProject';
import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { useHistoryManger } from 'src/hooks/history/useHistoryManger';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { selectCurrentAnalyticalModelSettings } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { ObjectIdWithVersion } from 'src/types/core.types';

export enum TransformationStatus {
    Transforming,
    Uploading,
    Complete,
    Failed,
}

export enum ShouldRenderUpdatedObjects {
    No = 'No',
    Yes = 'Yes',
}

export enum ShouldRunDetectorsOnCreatedObjects {
    No = 'No',
    Yes = 'Yes',
}

// return { executeTransformation, transformationStatus }
// executeTransformation - function encapsulating common logic for executing a transformation.
// transformationStatus - stateful variable to track the status of the transformation.
//
// The purpose of this hook is to encapsulate common logic for executing a transformation.
// For all transformations, we want to:
//     1. Make the transformation query (one of `GtmMeshTransformationAction`s)
//     2. Handle the response. Tranformations can result in modifying, creating, or deleting objects.
//         a. Update modified objects (can handle consistently: find the object in the store and update it)
//         b. Remove deleted objects (can handle consistently: find the object in the store and update it)
//         c. Add created objects (cannot handle consistently: we don't know where to add the object, so leave it up to consumer)
//         x. For each of the above, we want to render and run detectors as necessary.
//     3. Handle additional side effects
//         a. Some transformations affect other parts of the project state
//            (e.g. transforming the aggregate invalidates volumes).
//            Consumers can provide a handler to update bits of the store as necessary,
//            meaning that project state changes can be synchronized to file all together.
//     4. Transformations require addition of a history entry.
//     5. Synchronize the project file.
export function useTransformationManager() {
    const [transformationStatus, setTransformationStatus] = useState<
        TransformationStatus | undefined
    >(undefined);

    const { makeTransformationQuery } = useTransformationQuery(setTransformationStatus);
    const { handleModifiedObjects, handleDeletedObjects, handleCreatedObjects, handleSideEffects } =
        useResponseHandlers();
    const { addTransformationHistoryEntry } = useTransformationHistoryManager();
    const { uploadProject } = useProjectUploader(setTransformationStatus);
    const analyticalModelSettings =
        useAppSelector(selectCurrentAnalyticalModelSettings) ?? defaultAnalyticalModelSettings;

    async function executeTransformation(
        transformationAction: GtmMeshTransformationAction,
        renderUpdatedObjects: ShouldRenderUpdatedObjects,
        runDetectorsOnCreatedObjects: ShouldRunDetectorsOnCreatedObjects,
        objects: ObjectIdWithVersion[],
        params: GtmMeshTransformationParams,
        options?: {
            createdObjectsHandler?: (objects: ObjectIdWithVersion[]) => void;
            handleAdditionalSideEffects?: (
                transformationResponse: GtmMeshTransformationResponse,
            ) => void;
        },
    ) {
        return makeTransformationQuery(transformationAction, objects, params)
            .then(handleModifiedObjects(renderUpdatedObjects, analyticalModelSettings))
            .then(handleDeletedObjects)
            .then(
                handleCreatedObjects(
                    renderUpdatedObjects,
                    runDetectorsOnCreatedObjects,
                    analyticalModelSettings,
                    options?.createdObjectsHandler,
                ),
            )
            .then(handleSideEffects(options?.handleAdditionalSideEffects))
            .then(addTransformationHistoryEntry(transformationAction, params))
            .then(uploadProject)
            .then((transformationResponse: GtmMeshTransformationResponse) => {
                setTransformationStatus(TransformationStatus.Complete);
                return transformationResponse;
            })
            .catch((error) => {
                setTransformationStatus(TransformationStatus.Failed);
                return Promise.reject(error);
            })
            .finally(() => setTransformationStatus(undefined));
    }

    return { executeTransformation, transformationStatus };
}

function rejectTransformation() {
    return Promise.reject(new Error('Transformation failed.'));
}

function useTransformationQuery(setTransformationStatus: (status: TransformationStatus) => void) {
    const gooseContext = useGooseContext();
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();

    return {
        makeTransformationQuery: async (
            transformationAction: GtmMeshTransformationAction,
            objects: ObjectIdWithVersion[],
            params: GtmMeshTransformationParams,
        ) => {
            setTransformationStatus(TransformationStatus.Transforming);

            const { data, isError } = await GtmMeshTransformationTrigger(
                formGtmMeshTransformationBody(gooseContext!, transformationAction, objects, params),
            ).catch(rejectTransformation);

            return isError || !data ? rejectTransformation() : data;
        },
    };
}

function useResponseHandlers() {
    const { updateObjectAndRunDetectors, removeObject, runAllDetectorsOnObject, renderObject } =
        useConglomerateActionManager();

    return {
        handleModifiedObjects:
            (
                renderUpdatedObjects: ShouldRenderUpdatedObjects,
                analyticalModelSettings: GtmAnalyticalModelSettings,
            ) =>
            async (transformationResponse: GtmMeshTransformationResponse) => {
                transformationResponse.modified.forEach((obj) => {
                    updateObjectAndRunDetectors(obj, analyticalModelSettings);
                    if (renderUpdatedObjects === ShouldRenderUpdatedObjects.Yes) renderObject(obj);
                });
                return transformationResponse;
            },
        handleDeletedObjects: async (transformationResponse: GtmMeshTransformationResponse) => {
            transformationResponse.deleted.forEach((obj) => removeObject(obj));
            return transformationResponse;
        },
        handleCreatedObjects:
            (
                renderUpdatedObjects: ShouldRenderUpdatedObjects,
                runDetectorsOnCreatedObjects: ShouldRunDetectorsOnCreatedObjects,
                analyticalModelSettings: GtmAnalyticalModelSettings,
                createdObjectsHandler?: (objects: ObjectIdWithVersion[]) => void,
            ) =>
            async (transformationResponse: GtmMeshTransformationResponse) => {
                if (createdObjectsHandler) createdObjectsHandler(transformationResponse.created);
                transformationResponse.created.forEach((obj) => {
                    if (runDetectorsOnCreatedObjects === ShouldRunDetectorsOnCreatedObjects.Yes) {
                        runAllDetectorsOnObject(obj, analyticalModelSettings);
                    }
                    if (renderUpdatedObjects === ShouldRenderUpdatedObjects.Yes) renderObject(obj);
                });
                return transformationResponse;
            },
        handleSideEffects:
            (
                sideEffectsHandler?: (
                    transformationResponse: GtmMeshTransformationResponse,
                ) => void,
            ) =>
            async (transformationResponse: GtmMeshTransformationResponse) => {
                if (sideEffectsHandler) sideEffectsHandler(transformationResponse);
                return transformationResponse;
            },
    };
}

function useTransformationHistoryManager() {
    const { addSummarizedHistoryEntry } = useHistoryManger();

    return {
        addTransformationHistoryEntry:
            (
                transformationAction: GtmMeshTransformationAction,
                transformationParams: GtmMeshTransformationParams,
            ) =>
            async (transformationResponse: GtmMeshTransformationResponse) => {
                addSummarizedHistoryEntry(transformationAction, transformationParams);
                return transformationResponse;
            },
    };
}

function useProjectUploader(setTransformationStatus: (status: TransformationStatus) => void) {
    const { syncProject } = useProjectSynchronizer();

    return {
        uploadProject: async (transformationResponse: GtmMeshTransformationResponse) => {
            setTransformationStatus(TransformationStatus.Uploading);
            return syncProject()
                .then(() => transformationResponse)
                .catch(() => Promise.reject(new Error('Sync failed.')));
        },
    };
}
