import { createSelector } from '@reduxjs/toolkit';

import { defaultAnalyticalModelSettings } from 'src/apiClients/gtmCompute/gtmComputeApi';
import { GtmAnalyticalModelSettings } from 'src/gtmProject';
import { RootState } from 'src/store/store';
import { DetectionSettingsState } from 'src/store/ui/detectionSettings/detectionSettings.types';

export function detectionSettingsFromAnalyticalModelSettings(
    analyticalModelSettings: GtmAnalyticalModelSettings,
): DetectionSettingsState {
    return {
        degenerateTriangleSettings: {
            needleThresholdRatio:
                analyticalModelSettings.degenerateTriangleSettings.needleThresholdRatio.toString(),
            needleThresholdRatioValid: true,
            capMinAngleDegrees:
                analyticalModelSettings.degenerateTriangleSettings.capMinAngleDegrees.toString(),
            capMinAngleDegreesValid: true,
            needleCollapseLength:
                analyticalModelSettings.degenerateTriangleSettings.needleCollapseLength.toString(),
            needleCollapseLengthValid: true,
        },
        holeSettings: {
            holeSizeRatioTolerance:
                analyticalModelSettings.holeSettings.holeSizeRatioTolerance.toString(),
            holeSizeRatioToleranceValid: true,
        },
        selfIntersectionSettings: {
            tolerance: analyticalModelSettings.selfIntersectionSettings.tolerance.toString(),
            toleranceValid: true,
        },
    };
}

export function analyticalModelSettingsFromDetectionSettings(
    existingAnalyticalModelSettings: GtmAnalyticalModelSettings,
    detectionSettings: DetectionSettingsState,
): GtmAnalyticalModelSettings {
    return {
        ...existingAnalyticalModelSettings,
        degenerateTriangleSettings: {
            needleThresholdRatio: Number(
                detectionSettings.degenerateTriangleSettings.needleThresholdRatio,
            ),
            capMinAngleDegrees: Number(
                detectionSettings.degenerateTriangleSettings.capMinAngleDegrees,
            ),
            needleCollapseLength: Number(
                detectionSettings.degenerateTriangleSettings.needleCollapseLength,
            ),
        },
        holeSettings: {
            holeSizeRatioTolerance: Number(detectionSettings.holeSettings.holeSizeRatioTolerance),
        },
        selfIntersectionSettings: {
            tolerance: Number(detectionSettings.selfIntersectionSettings.tolerance),
        },
    };
}

export const initialDetectionSettingsState = detectionSettingsFromAnalyticalModelSettings(
    defaultAnalyticalModelSettings,
);

type SelectorTypeAll = (state: RootState) => DetectionSettingsState;
type SelectorTypeString = (state: RootState) => string;
type SelectorTypeBoolean = (state: RootState) => boolean;

const detectionSettingsState = (state: RootState): DetectionSettingsState =>
    state.detectionSettings;

export const selectAllDetectionSettings: SelectorTypeAll = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) => detectionSettingsStateRoot,
);

export const selectNeedleThresholdRatio: SelectorTypeString = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.needleThresholdRatio,
);

export const selectNeedleThresholdRatioValid: SelectorTypeBoolean = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.needleThresholdRatioValid,
);

export const selectCapMinAngleDegrees: SelectorTypeString = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.capMinAngleDegrees,
);

export const selectCapMinAngleDegreesValid: SelectorTypeBoolean = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.capMinAngleDegreesValid,
);

export const selectNeedleCollapseLength: SelectorTypeString = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.needleCollapseLength,
);

export const selectNeedleCollapseLengthValid: SelectorTypeBoolean = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.needleCollapseLengthValid,
);
export const selectHoleSizeRatioTolerance: SelectorTypeString = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) => detectionSettingsStateRoot.holeSettings.holeSizeRatioTolerance,
);

export const selectHoleSizeRatioToleranceValid: SelectorTypeBoolean = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.holeSettings.holeSizeRatioToleranceValid,
);

export const selectSelfIntersectionTolerance: SelectorTypeString = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) => detectionSettingsStateRoot.selfIntersectionSettings.tolerance,
);

export const selectSelfIntersectionToleranceValid: SelectorTypeBoolean = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.selfIntersectionSettings.toleranceValid,
);
