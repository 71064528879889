import { useParams } from 'react-router-dom';

import { useCustomUpsertFileByPathMutation } from 'src/apiClients/file/customFileEndpoints';

export function useLazyUploadFile() {
    const [UpdateFileTrigger] = useCustomUpsertFileByPathMutation();
    const { orgUuid, workspaceUuid } = useParams();

    async function UploadFileTrigger(file: File) {
        return UpdateFileTrigger({
            organisationId: orgUuid!,
            workspaceId: workspaceUuid!,
            filePath: file.name,
            uploadFile: file,
        });
    }

    return [UploadFileTrigger];
}
