import type { ListedObject } from '../goose/enhancedGooseClient';
import { Filter } from '../types';

export const filterByDate = (object: ListedObject, folder: Filter) => {
    const comparisonDate = new Date();
    const days = parseInt(folder.comparison[0].slice(0, -1), 10);
    comparisonDate.setDate(comparisonDate.getDate() - days);

    if (folder.condition === 'greaterThan') {
        return new Date(object.created_at) > comparisonDate;
    }

    if (folder.condition === 'equals') {
        const objectDate = new Date(object.created_at);
        const objectDateString = `${objectDate.getDate()}-${objectDate.getMonth()}-${objectDate.getFullYear()}`;
        const comparisonDateString = `${comparisonDate.getDate()}-${comparisonDate.getMonth()}-${
            comparisonDate.getFullYear
        }`;
        return objectDateString === comparisonDateString;
    }

    if (folder.condition === 'lessThan') {
        return new Date(object.created_at) < comparisonDate;
    }

    return false;
};
