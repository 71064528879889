import { GtmHistoryOperation, GtmHistoryEntry } from 'src/gtmProject';

export function makeHistoryEntry(
    operation: GtmHistoryOperation,
    currentProjectVersionId: string,
): GtmHistoryEntry {
    return {
        operation,
        versionId: currentProjectVersionId,
    };
}
