import { useLazyListGtmProjectFiles } from 'src/hooks/evoContext/useLazyListGtmProjectFiles';
import { useLazyListTriangleMeshes } from 'src/hooks/evoContext/useLazyListTriangleMeshes';
import { setWorkspaceName, setProjectFiles, setUserTriangleMeshes } from 'src/store/evo/evoSlice';
import { clearProject } from 'src/store/project/projectSlice';
import { useAppDispatch } from 'src/store/store';
import { clearSceneObjects } from 'src/store/visualization/visualizationSlice';
import { INTERNAL_OBJECT_FOLDER } from 'src/strings';
import { ObjectId, VersionId, GeoscienceObject } from 'src/types/core.types';

export function useEvoContextManager() {
    const dispatch = useAppDispatch();
    const [ListGtmProjectFilesTrigger] = useLazyListGtmProjectFiles();
    const [ListTriangleMeshesTrigger] = useLazyListTriangleMeshes();

    async function loadWorkspaceTriangleMeshes() {
        const triangleMeshes = (await ListTriangleMeshesTrigger())
            ?.filter(({ path }) => !path.includes(INTERNAL_OBJECT_FOLDER))
            .map(
                ({ object_id, version_id, name, schema }): GeoscienceObject => ({
                    id: object_id as ObjectId,
                    version: version_id as VersionId,
                    name,
                    schema,
                }),
            );

        dispatch(setUserTriangleMeshes(triangleMeshes));
    }

    async function swapWorkspace(workspaceName: string) {
        dispatch(setWorkspaceName(workspaceName));
        dispatch(clearProject());
        dispatch(clearSceneObjects());

        const gtmProjectFiles = (await ListGtmProjectFilesTrigger())?.map((file) => file.name);
        dispatch(setProjectFiles(gtmProjectFiles));
        loadWorkspaceTriangleMeshes();
    }

    return {
        loadWorkspaceTriangleMeshes,
        swapWorkspace,
    };
}
