import { createSelector } from '@reduxjs/toolkit';

import { selectCurrentModelSelectedObject } from 'src/store/project/selectors';

import type { RootState } from '../store';
import { VisualizationState, SceneObjectMap, SceneObjectData } from './visualizationSlice.types';

type SelectorTypeBoolean = (state: RootState) => boolean;
type SelectorTypeSceneObjectMap = (state: RootState) => SceneObjectMap;
type SelectorTypeSceneObjectData = (state: RootState) => SceneObjectData | undefined;
type SelectorTypeStringArray = (state: RootState) => string[];
type SelectorTypeStringOrUndefined = (state: RootState) => string | undefined;
type SelectorTypeNumberOrUndefined = (state: RootState) => number | undefined;

export const initialState: VisualizationState = {
    overlays: { scalebar: true, orientation: true },
    sceneObjectMap: {},
};

const visualizationState = (state: RootState): VisualizationState =>
    state.visualization ?? initialState;

export const scalebarVisible: SelectorTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.scalebar,
);

export const orientationVisible: SelectorTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.orientation,
);

export const sceneObjectMap: SelectorTypeSceneObjectMap = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.sceneObjectMap,
);

export const sceneObjectById = (objectId: string): SelectorTypeSceneObjectData =>
    createSelector(
        visualizationState,
        (visualizationStateRoot) => visualizationStateRoot.sceneObjectMap[objectId],
    );

export const selectedSceneObjects: SelectorTypeSceneObjectMap = createSelector(
    visualizationState,
    (visualizationStateRoot) =>
        Object.entries(visualizationStateRoot.sceneObjectMap)
            .filter(([_, sceneObjectData]) => sceneObjectData.isSelected)
            .reduce(
                (result, [objectId, sceneObjectData]) => ({
                    ...result,
                    [objectId]: sceneObjectData,
                }),
                {} as SceneObjectMap,
            ),
);

export const selectedSceneObjectIds: SelectorTypeStringArray = createSelector(
    selectedSceneObjects,
    (selectedObjects) => Object.entries(selectedObjects).map(([objectId, _]) => objectId),
);

export const firstSelectedSceneObjectId: SelectorTypeStringOrUndefined = createSelector(
    selectedSceneObjectIds,
    (selectedObjects) => selectedObjects[0],
);

export const selectNumOfTrianglesForCurrentModelSelectedObject: SelectorTypeNumberOrUndefined =
    createSelector(
        selectCurrentModelSelectedObject,
        sceneObjectMap,
        (currentModelSelectedObject, currentSceneObjectMap) => {
            if (!currentModelSelectedObject) {
                return 0;
            }
            const selectedAggregateGeometry = currentSceneObjectMap[
                currentModelSelectedObject.id
            ] as SceneObjectData;
            return selectedAggregateGeometry?.gooseObject?.object?.triangles?.indices?.length ?? 0;
        },
    );

export const selectNumOfVerticesForCurrentModelSelectedObject: SelectorTypeNumberOrUndefined =
    createSelector(
        selectCurrentModelSelectedObject,
        sceneObjectMap,
        (currentModelSelectedObject, currentSceneObjectMap) => {
            if (!currentModelSelectedObject) {
                return 0;
            }
            const selectedAggregateGeometry = currentSceneObjectMap[
                currentModelSelectedObject.id
            ] as SceneObjectData;
            return selectedAggregateGeometry?.gooseObject?.object?.triangles?.vertices?.length ?? 0;
        },
    );
