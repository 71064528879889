import { selectCurrentModel, selectCurrentModelSelectedObject } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { selectShouldShowPublishPanel } from 'src/store/ui/publishPanel';
import { ModelSettingsPanel } from 'src/visualization/SettingsPanel/components/ModelSettingsPanel';
import { ObjectsSettingsPanel } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel';
import { PublishPanel } from 'src/visualization/SettingsPanel/components/PublishPanel';

const styles = { position: 'absolute', zIndex: 1, right: '16px', top: '16px' };

export function SettingsPanel() {
    const currentModel = useAppSelector(selectCurrentModel);
    const currentModelSelectedObject = useAppSelector(selectCurrentModelSelectedObject);
    const shouldShowPublishPanel = useAppSelector(selectShouldShowPublishPanel);

    if (shouldShowPublishPanel) {
        return <PublishPanel sx={styles} />;
    }

    if (currentModelSelectedObject) {
        return <ObjectsSettingsPanel sx={styles} />;
    }

    if (currentModel) {
        return <ModelSettingsPanel />;
    }

    return null;
}
