/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialRemeshSettingsState } from 'src/store/ui/remeshSettings/selectors';

import { RemeshSettingsState } from './remeshSettings.types';

export const remeshSettingsSlice = createSlice({
    name: 'remeshSettings',
    initialState: initialRemeshSettingsState,
    reducers: {
        setAllRemeshSettings(
            state: RemeshSettingsState,
            { payload: newRemeshSettings }: PayloadAction<RemeshSettingsState>,
        ) {
            return newRemeshSettings;
        },
        setPatchAngleTolerance(
            state: RemeshSettingsState,
            { payload: newPatchAngleTolerance }: PayloadAction<string>,
        ) {
            state.patchAngleTolerance = newPatchAngleTolerance;
        },
        setPatchAngleToleranceValid(
            state: RemeshSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.patchAngleToleranceValid = isValidSetting;
        },
        setMaxChordalError(
            state: RemeshSettingsState,
            { payload: newMaxChordalError }: PayloadAction<string>,
        ) {
            state.maxChordalError = newMaxChordalError;
        },
        setMaxChordalErrorValid(
            state: RemeshSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.maxChordalErrorValid = isValidSetting;
        },
        setTargetElementSize(
            state: RemeshSettingsState,
            { payload: newTargetElementSize }: PayloadAction<string>,
        ) {
            state.targetElementSize = newTargetElementSize;
        },
        setTargetElementSizeValid(
            state: RemeshSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.targetElementSizeValid = isValidSetting;
        },
        setShapeQualityWeight(
            state: RemeshSettingsState,
            { payload: newShapeQualityWeight }: PayloadAction<string>,
        ) {
            state.shapeQualityWeight = newShapeQualityWeight;
        },
        setShapeQualityWeightValid(
            state: RemeshSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.shapeQualityWeightValid = isValidSetting;
        },
    },
});

export const {
    setAllRemeshSettings,
    setPatchAngleTolerance,
    setPatchAngleToleranceValid,
    setMaxChordalError,
    setMaxChordalErrorValid,
    setTargetElementSize,
    setTargetElementSizeValid,
    setShapeQualityWeight,
    setShapeQualityWeightValid,
} = remeshSettingsSlice.actions;
