import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ComputeTaskQuery } from 'src/apiClients/compute/computeApi.types';
import { computeAsyncTask } from 'src/apiClients/compute/utils';
import { TASK_ROOT_TASK } from 'src/constants';
import { GtmAnalyticalModelSettings } from 'src/gtmProject';
import { ObjectIdWithVersion } from 'src/types/core.types';

import {
    DEFAULT_CAPMINANGLEDEGREES,
    DEFAULT_HOLESIZERATIOTOLERANCE,
    DEFAULT_MAXCHORDALERROR,
    DEFAULT_NEEDLECOLLAPSELENGTH,
    DEFAULT_NEEDLETHRESHOLDRATIO,
    DEFAULT_PATCHANGLETOLERANCE,
    DEFAULT_SHAPEQUALITYWEIGHT,
    DEFAULT_TARGETH,
    DEFAULT_TOLERANCE,
} from './gtmComputeApi.constants';
import {
    GtmMeshGetMeshDataAction,
    GtmMeshData,
    GtmGooseContext,
    GtmMeshDataRequestBody,
    GtmMeshDetectorRequestBody,
    GtmMeshDetectionData,
    GtmMeshTransformationRequestBody,
    GtmMeshTransformationResponse,
    GtmMeshDetectorAction,
    GtmMeshTransformationAction,
    GtmMeshDetectorParams,
    GtmMeshTransformationParams,
    UnparsedTransformationResponse,
} from './gtmComputeApi.types';

export const defaultAnalyticalModelSettings: GtmAnalyticalModelSettings = {
    degenerateTriangleSettings: {
        needleThresholdRatio: DEFAULT_NEEDLETHRESHOLDRATIO,
        capMinAngleDegrees: DEFAULT_CAPMINANGLEDEGREES,
        needleCollapseLength: DEFAULT_NEEDLECOLLAPSELENGTH,
    },
    holeSettings: { holeSizeRatioTolerance: DEFAULT_HOLESIZERATIOTOLERANCE },
    selfIntersectionSettings: { tolerance: DEFAULT_TOLERANCE },
    remeshSettings: {
        patchAngleTolerance: DEFAULT_PATCHANGLETOLERANCE,
        maxChordalError: DEFAULT_MAXCHORDALERROR,
        shapeQualityWeight: DEFAULT_SHAPEQUALITYWEIGHT,
        targetH: DEFAULT_TARGETH,
    },
};

export const gtmComputeApi = createApi({
    reducerPath: 'gtmTaskApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/' }), // unused when we're using queryFn
    endpoints: (builder) => ({
        gtmMeshData: builder.query<GtmMeshData, ComputeTaskQuery<GtmMeshDataRequestBody>>({
            queryFn: async (query) => {
                const res = await computeAsyncTask(TASK_ROOT_TASK, query);
                if (res.error) return res;
                const taskResult = res.data;
                return {
                    data: {
                        vertices: new Float32Array(JSON.parse(taskResult.points).flat()),
                        triangles: new Int32Array(JSON.parse(taskResult.indices).flat()),
                    },
                };
            },
        }),

        gtmMeshDetector: builder.query<
            GtmMeshDetectionData,
            ComputeTaskQuery<GtmMeshDetectorRequestBody>
        >({
            queryFn: async (query) => {
                const res = await computeAsyncTask(TASK_ROOT_TASK, query);
                if (res.error) return res;

                const taskResult: GtmMeshDetectionData = res.data
                    ? Object.values<string>(res.data).map((val) => JSON.parse(val))
                    : [];
                return { data: taskResult };
            },
        }),

        gtmMeshTransformation: builder.query<
            GtmMeshTransformationResponse,
            ComputeTaskQuery<GtmMeshTransformationRequestBody>
        >({
            queryFn: async (query) => {
                const res = await computeAsyncTask(TASK_ROOT_TASK, query);
                if (res.error) return res;

                const taskResult = Object.fromEntries(
                    Object.entries(res.data as UnparsedTransformationResponse).map(
                        ([key, value]) => [key, JSON.parse(value)],
                    ),
                );
                return { data: taskResult };
            },
        }),
    }),
});

export const {
    useGtmMeshDataQuery,
    useLazyGtmMeshDataQuery,
    useGtmMeshDetectorQuery,
    useLazyGtmMeshDetectorQuery,
    useGtmMeshTransformationQuery,
    useLazyGtmMeshTransformationQuery,
} = gtmComputeApi;

export const formGtmMeshDataRequestBody = (
    gooseContext: GtmGooseContext,
    objects: ObjectIdWithVersion[],
): ComputeTaskQuery<GtmMeshDataRequestBody> => ({
    orgId: gooseContext.orgId,
    parameters: {
        gooseContext,
        action: GtmMeshGetMeshDataAction.GetMeshData,
        objects,
        params: {},
    },
});

export const formGtmMeshDetectorBody = (
    gooseContext: GtmGooseContext,
    detectorAction: GtmMeshDetectorAction,
    objects: ObjectIdWithVersion[],
    params: GtmMeshDetectorParams,
): ComputeTaskQuery<GtmMeshDetectorRequestBody> => ({
    orgId: gooseContext.orgId,
    parameters: {
        gooseContext,
        action: detectorAction,
        objects,
        params,
    },
});

export const formGtmMeshTransformationBody = (
    gooseContext: GtmGooseContext,
    transformationAction: GtmMeshTransformationAction,
    objects: ObjectIdWithVersion[],
    params: GtmMeshTransformationParams,
): ComputeTaskQuery<GtmMeshTransformationRequestBody> => ({
    orgId: gooseContext.orgId,
    parameters: {
        gooseContext,
        action: transformationAction,
        objects,
        params,
    },
});
