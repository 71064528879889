import { useLazyListObjectsQuery } from '@local/api-clients/src/goose/enhancedGooseClient';
import { useParams } from 'react-router-dom';

import { MESH_SCHEMA } from 'src/constants';

export function useLazyListTriangleMeshes() {
    const [listObjects] = useLazyListObjectsQuery();
    const { orgUuid, workspaceUuid } = useParams();

    async function ListTriangleMeshesTrigger() {
        const { data } = await listObjects({
            orgId: orgUuid!,
            workspaceId: workspaceUuid!,
            schemaId: [MESH_SCHEMA],
        });

        return data?.objects;
    }

    return [ListTriangleMeshesTrigger];
}
