import { createBentleyAccessTokenBaseQuery } from '@local/login/dist/apiClients/identityClient/requests/BentleyAccessTokenApi';
import { createApi } from '@reduxjs/toolkit/query/react';

import { getUrlConfig } from '../utils/getUrlConfig';

export const gooseClient = createApi({
    tagTypes: ['goose'],
    reducerPath: 'goose',
    baseQuery: createBentleyAccessTokenBaseQuery(getUrlConfig),
    endpoints: () => ({}),
});
