export const UPLOAD_OBJECTS_DIALOG_TITLE = 'Upload Objects';
export const OBJECT_FILE_EXTENSION = '.obj';
export const FILE_SIZE_LIMIT_MB = 100;
export const FILE_SIZE_LIMIT_BYTES = FILE_SIZE_LIMIT_MB * 1024 * 1024;
export const DELETE_ARTIFACT_ERROR_MESSAGE = 'Error deleting artifact';
export const CLOSE_LABEL = 'Close';
export const DRAG_DROP_PROMPT = 'Drag & drop files or';
export const CHOOSE_FILES_PROMPT = 'Choose files';
export const getInvalidFileTypeMessage = (fileName: string) =>
    `${fileName} must be a ${OBJECT_FILE_EXTENSION} file`;
export const getInvalidFileSizeMessage = (fileName: string) =>
    `${fileName} exceeds max limit of ${FILE_SIZE_LIMIT_MB} MB`;
export const getAddButtonLabel = (numToAdd: number) =>
    `Add ${numToAdd > 0 ? `${numToAdd} ` : ''}Object${numToAdd === 1 ? '' : 's'}`;
