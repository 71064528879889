import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const IssuesLoading = () => {
    const applyTrace = useTrace('issues-loading');

    return (
        <Box automation-id={applyTrace()}>
            <IssueLoadingRow />
            <Divider />
            <IssueLoadingRow />
            <Divider />
        </Box>
    );
};

const IssueLoadingRow = () => (
    <Stack
        sx={(theme) => ({
            justifyContent: 'space-between',
            padding: theme.spacing(2),
        })}
        direction="row"
    >
        <Stack direction="row">
            <Skeleton variant="circular" width={26} height={26} />
            <Skeleton
                variant="text"
                sx={(theme) => ({ fontSize: '0.75rem', marginLeft: theme.spacing(2) })}
                width={100}
            />
        </Stack>
        <Stack direction="row">
            <Skeleton variant="circular" width={26} height={26} />
            <Skeleton
                variant="circular"
                width={26}
                height={26}
                sx={(theme) => ({ marginLeft: theme.spacing(1) })}
            />
        </Stack>
    </Stack>
);
