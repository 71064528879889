import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const BoxSearchIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <path d="M11.9994 13.2999C11.8694 13.2999 11.7394 13.2699 11.6194 13.1999L2.78936 8.0899C2.42936 7.8799 2.30934 7.41987 2.51934 7.05987C2.72934 6.69987 3.17934 6.57985 3.54934 6.78985L11.9994 11.6799L20.3993 6.81988C20.7593 6.60988 21.2193 6.7399 21.4293 7.0899C21.6393 7.4499 21.5094 7.90987 21.1594 8.11987L12.3894 13.1999C12.2594 13.2599 12.1294 13.2999 11.9994 13.2999Z" />
        <path d="M12 22.36C11.59 22.36 11.25 22.02 11.25 21.61V12.54C11.25 12.13 11.59 11.79 12 11.79C12.41 11.79 12.75 12.13 12.75 12.54V21.61C12.75 22.02 12.41 22.36 12 22.36Z" />
        <path d="M11.9989 22.75C11.0889 22.75 10.2189 22.54 9.5589 22.17L4.2189 19.21C2.7689 18.41 1.62891 16.48 1.62891 14.82V9.15997C1.62891 7.49997 2.7689 5.58001 4.2189 4.77001L9.5589 1.80999C10.9189 1.04999 13.0589 1.04999 14.4289 1.80999L19.7689 4.77001C21.2189 5.57001 22.3589 7.49997 22.3589 9.15997V12.82C22.3589 13.23 22.0189 13.57 21.6089 13.57C21.1989 13.57 20.8589 13.23 20.8589 12.82V9.15997C20.8589 8.05997 20.0089 6.62002 19.0489 6.09002L13.7089 3.13C12.7989 2.62 11.2089 2.62 10.2989 3.13L4.95889 6.09002C3.98889 6.63002 3.1489 8.05997 3.1489 9.15997V14.82C3.1489 15.92 3.99889 17.36 4.95889 17.89L10.2989 20.85C11.1789 21.34 12.8389 21.34 13.7089 20.85C14.0689 20.65 14.5289 20.78 14.7289 21.14C14.9289 21.5 14.7989 21.96 14.4389 22.16C13.7789 22.54 12.9089 22.75 11.9989 22.75Z" />
        <path d="M19.2 22.15C17.02 22.15 15.25 20.38 15.25 18.2C15.25 16.02 17.02 14.25 19.2 14.25C21.38 14.25 23.15 16.02 23.15 18.2C23.15 20.38 21.38 22.15 19.2 22.15ZM19.2 15.75C17.85 15.75 16.75 16.85 16.75 18.2C16.75 19.55 17.85 20.65 19.2 20.65C20.55 20.65 21.65 19.55 21.65 18.2C21.65 16.85 20.55 15.75 19.2 15.75Z" />
        <path d="M22.9995 22.75C22.8095 22.75 22.6195 22.68 22.4695 22.53L21.4695 21.53C21.1795 21.24 21.1795 20.7599 21.4695 20.4699C21.7595 20.1799 22.2395 20.1799 22.5295 20.4699L23.5295 21.4699C23.8195 21.7599 23.8195 22.24 23.5295 22.53C23.3795 22.68 23.1895 22.75 22.9995 22.75Z" />
    </SvgIcon>
);
