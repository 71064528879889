import { theme } from '@local/web-design-system/dist/styles/theme';
import {
    getHubCodeFromParams,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { FormattedDate } from 'react-intl';
import { useParams, Link as RouterLink } from 'react-router-dom';

import { WORKSPACE_PATH } from 'src/constants';

import { useStyles } from './SelectionTables.styles';
import { WorkspaceSelectionTableProps } from './SelectionTables.types';

export function WorkspaceSelectionTable({ data }: WorkspaceSelectionTableProps) {
    const { classes } = useStyles();

    const params = useParams();

    const rows =
        data?.results?.map((workspace) => ({
            name: workspace.name,
            description: workspace.description,
            permission: workspace.current_user_role,
            updated_at: workspace.updated_at,
            workspaceId: workspace.id,
        })) || [];

    return (
        <Grid container direction="column" alignContent="space-around">
            <Grid item style={{ position: 'relative' }}>
                <TableContainer>
                    <Table
                        size="small"
                        className={classes.historyTable}
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Description</TableCell>
                                <TableCell align="right">Your Permission</TableCell>
                                <TableCell align="right">Last Updated</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows &&
                                rows.map((row) => (
                                    <TableRow key={row.name} hover>
                                        <TableCell component="th" scope="row">
                                            <RouterLink
                                                to={`/${getOrgUuidFromParams(
                                                    params,
                                                )}/hub/${getHubCodeFromParams(params)}${WORKSPACE_PATH}/${row.workspaceId}`}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: theme.palette.info.main,
                                                }}
                                            >
                                                <Typography variant="body2"> {row.name}</Typography>
                                            </RouterLink>
                                        </TableCell>
                                        <TableCell align="right">{row.description}</TableCell>
                                        <TableCell align="right">{row.permission}</TableCell>
                                        <TableCell align="right">
                                            <span style={{ paddingRight: '8px' }}>
                                                <FormattedDate
                                                    value={row.updated_at}
                                                    month="short"
                                                    year="numeric"
                                                    day="2-digit"
                                                />
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
