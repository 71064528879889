import type { ListedObject, ListObjectsResponse } from '../goose/enhancedGooseClient';
import { Filter } from '../types';
import { filterByDate } from './filterByDate';
import { filterBySchema } from './filterBySchema';

export const filterByProperty = (data: ListObjectsResponse, folderSchema: Filter[]) => {
    if (!folderSchema.length) {
        return [];
    }

    const returnProperFilter = (gooseObject: ListedObject, folder: Filter) => {
        if (folder.property === 'created_at') {
            return filterByDate(gooseObject, folder);
        }

        if (folder.property === 'schema') {
            return filterBySchema(gooseObject, folder);
        }

        return false;
    };

    return data.objects.filter((gooseObject) =>
        folderSchema.every((folder) => returnProperFilter(gooseObject, folder)),
    );
};
