import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { FillAllHoles } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction/FillAllHoles';
import { FixDegenerateTriangles } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction/FixDegenerateTriangles';

import { FixSelfIntersections } from './FixSelfIntersections';

interface TransformationActionProps {
    action: GtmMeshTransformationAction;
}

export const TransformationAction = ({ action }: TransformationActionProps) => {
    switch (action) {
        case GtmMeshTransformationAction.FillHoles:
            return <FillAllHoles />;
        case GtmMeshTransformationAction.RemoveDegenerateTris:
            return <FixDegenerateTriangles />;
        case GtmMeshTransformationAction.Intersect:
            return <FixSelfIntersections />;
        default:
            return null;
    }
};
