export const REMESH_LABEL = 'Remesh';
export const APPLY_LABEL = 'Apply';

export const PATCH_ANGLE_TOLERANCE = 'Angle threshold';
export const MAX_CHORDAL_ERROR = 'Maximum deviation';
export const TARGET_H = 'Target element size';
export const SHAPE_QUALITY_WEIGHT = 'Quality weight';

export const STATUS_TRANSFORMING = 'Remeshing...';
export const STATUS_COMPLETE = 'Remeshing complete';
export const STATUS_FAILED = 'Failed to remesh';

export const PATCH_ANGLE_TOLERANCE_INFO =
    'An angle (in degrees) between two adjacent triangles greater than this value will cause the shared edge to be preserved. Higher values allow for better remeshing but may result in some details being lost. Allowable range is 0 to 45 degrees.';
export const MAX_CHORDAL_ERROR_INFO =
    'The maximum allowable deviation from the original surface. Negative values indicate a percentage error from the local radius (e.g. -0.005 means 0.5% error).';
export const TARGET_H_INFO =
    'The target element size for the remeshed mesh. A value of 0 will use the size of nearby hard edges.';
export const SHAPE_QUALITY_WEIGHT_INFO =
    'The weight of shape quality in the remeshing process, between 0 and 1. Values closer to 1 prefer shape quality over element size, while values closer to 0 prefer element size over shape quality.';
