import { CameraTools } from '@local/webviz/dist/components/CameraTools';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { RemeshButton } from './RemeshButton';
import { useStyles } from './Toolbar.styles';

export function Toolbar() {
    const { classes } = useStyles();

    return (
        <Grid container item className={classes.root} justifyContent="center">
            <Grid container item className={classes.tools} alignItems="center">
                <CameraTools labelsOn />
                <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                        paddingLeft: 1,
                        paddingRight: 1,
                    }}
                />
                <RemeshButton />
            </Grid>
        </Grid>
    );
}
