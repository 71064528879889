import {
    XyzContext,
    XyzInstanceContextValue,
    createXyzInstanceContext,
} from '@local/webviz/dist/context/createXyzInstanceContext';
import { XyzInstance } from '@local/webviz/dist/types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'src/store/store';
import { selectShouldShowSetBoundaryDialog } from 'src/store/ui/projectPanel';
import { CrossSectionPanel } from 'src/visualization/CrossSectionPanel/CrossSectionPanel';
import { ProjectPanel } from 'src/visualization/ProjectPanel/ProjectPanel';
import { SettingsPanel } from 'src/visualization/SettingsPanel/SettingsPanel';

import { Plot } from '../Plot/Plot';

function ContentArea(
    xyzInstanceInitialized: boolean,
    setXyzInstanceContextValue: React.Dispatch<
        React.SetStateAction<XyzInstanceContextValue | null>
    >,
) {
    const { workspaceUuid } = useParams();
    const shouldShowBoundaryDialog = useAppSelector(selectShouldShowSetBoundaryDialog);
    const shouldShowExtraPanels = !shouldShowBoundaryDialog;

    return (
        <>
            {xyzInstanceInitialized && workspaceUuid && (
                <>
                    <ProjectPanel
                        sx={{ position: 'absolute', zIndex: 1, left: '16px', top: '16px' }}
                    />
                    {shouldShowExtraPanels && (
                        <>
                            <SettingsPanel />
                            <CrossSectionPanel />
                        </>
                    )}
                </>
            )}
            <Plot
                initialized={xyzInstanceInitialized}
                onInitialized={(xyzInstance: XyzInstance) =>
                    setXyzInstanceContextValue(createXyzInstanceContext(xyzInstance))
                }
            />
        </>
    );
}

export function Visualization() {
    const [xyzInstanceContextValue, setXyzInstanceContextValue] =
        useState<XyzInstanceContextValue | null>(null);
    const xyzInstanceInitialized = Boolean(xyzInstanceContextValue);

    return (
        <XyzContext.Provider value={xyzInstanceContextValue}>
            {ContentArea(xyzInstanceInitialized, setXyzInstanceContextValue)}
        </XyzContext.Provider>
    );
}
