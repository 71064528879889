import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { DEFAULT_PANEL_WIDTH } from 'src/styles';

const styles = (theme: Theme) =>
    ({
        root: {
            width: DEFAULT_PANEL_WIDTH,
        },
        basePaper: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        volumesLabel: {
            paddingTop: theme.spacing(0.5),
        },
        volumesText: {
            paddingBottom: theme.spacing(0.5),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
