import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { MESH_SCHEMA } from 'src/constants';
import {
    useTransformationManager,
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnCreatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import { setVolumes } from 'src/store/project/projectSlice';
import { selectCurrentProjectVolumes, selectCurrentShowVolumes } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { ObjectIdWithVersion } from 'src/types/core.types';
import { decorateNewObject } from 'src/utils/decorateObject';

export function useVolumesManager() {
    const dispatch = useAppDispatch();
    const { executeTransformation, transformationStatus: volumesComputationStatus } =
        useTransformationManager();
    const { removeGtmObject } = useSceneObjectDataManager();
    const shouldRenderVolumes = useAppSelector(selectCurrentShowVolumes);
    const currentVolumes = useAppSelector(selectCurrentProjectVolumes);

    async function computeVolumes(aggregateGeometry: ObjectIdWithVersion) {
        await executeTransformation(
            GtmMeshTransformationAction.SeparateVolumes,
            shouldRenderVolumes ? ShouldRenderUpdatedObjects.Yes : ShouldRenderUpdatedObjects.No,
            ShouldRunDetectorsOnCreatedObjects.No,
            [aggregateGeometry],
            {},
            {
                createdObjectsHandler: (createdObjects) => {
                    currentVolumes.forEach((obj) => removeGtmObject(obj.id));
                    const newVolumes = createdObjects.map((obj, index) =>
                        decorateNewObject(obj, `Volume ${index + 1}`, MESH_SCHEMA),
                    );
                    dispatch(setVolumes(newVolumes));
                },
            },
        );
    }

    return { computeVolumes, volumesComputationStatus };
}
