import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { useSelection } from '@local/webviz/dist/context/hooks/useSelection';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useSceneObjectDataManager } from 'src/hooks';
import {
    useBoundaryCreationManager,
    BoundaryCreationState,
} from 'src/hooks/boundaryCreation/useBoundaryCreationManger';
import {
    selectCurrentModelInputObjects,
    selectCurrentProjectModelNames,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { CANCEL_LABEL } from 'src/strings';
import { ObjectId, ObjectIdWithVersion } from 'src/types/core.types';
import { boundingBoxToGtmBounds } from 'src/utils/typeTransformations';
import {
    BoundingBox,
    getBoundingBoxSnapshot,
    computeBoundingBoxFromCenter,
} from 'src/utils/xyzUtils';
import { CoordinatesInput } from 'src/visualization/Common/CoordinatesInput';
import { CoordinatesDisabledState } from 'src/visualization/Common/CoordinatesInput.types';

import {
    BOUNDING_BOX_NO_BOUNDS_TITLE,
    BOUNDING_BOX_TITLE,
    NAME_TITLE,
    BOUNDING_BOX_DEFAULT_NAME,
    MAX_TITLE,
    MIN_TITLE,
    ACCEPT,
    ERROR_MAX_MUST_GREATER_THAN_MIN,
    ERROR_DUPLICATE_NAME,
    ENCLOSE_ENTIRE_MODEL,
    NO_SELECTION_TEXT,
} from './BoundaryCreationDialog.constants';
import {
    BoundaryCreationDialogErrorState,
    BoundaryCreationDialogProps,
} from './BoundaryCreationDialog.types';
import { BoundaryCreationModal } from './BoundaryCreationModal';

const BOUNDARY_CREATION_VIEW_ID = 'boundary-creation';

const defaultErrorState: BoundaryCreationDialogErrorState = {
    minErrorState: {
        isErrorX: false,
        isErrorY: false,
        isErrorZ: false,
    },
    maxErrorState: {
        isErrorX: false,
        isErrorY: false,
        isErrorZ: false,
    },
};

function isBoundingBoxError(errorState: BoundaryCreationDialogErrorState): boolean {
    const { minErrorState, maxErrorState } = errorState;
    return (
        minErrorState.isErrorX ||
        minErrorState.isErrorY ||
        minErrorState.isErrorZ ||
        maxErrorState.isErrorX ||
        maxErrorState.isErrorY ||
        maxErrorState.isErrorZ
    );
}

function detectBoundingBoxError(boundingBox: BoundingBox): BoundaryCreationDialogErrorState {
    const { xMin, yMin, zMin, xMax, yMax, zMax } = boundingBox;

    const minErrorState = {
        isErrorX: !Number.isFinite(xMin) || xMax <= xMin,
        isErrorY: !Number.isFinite(yMin) || yMax <= yMin,
        isErrorZ: !Number.isFinite(zMin) || zMax <= zMin,
    };
    const maxErrorState = {
        isErrorX: !Number.isFinite(xMax) || xMax <= xMin,
        isErrorY: !Number.isFinite(yMax) || yMax <= yMin,
        isErrorZ: !Number.isFinite(zMax) || zMax <= zMin,
    };
    return {
        minErrorState,
        maxErrorState,
    };
}

export function BoundaryCreationDialog({ onClose }: Readonly<BoundaryCreationDialogProps>) {
    const applyTrace = useTrace('boundary-creation-panel');

    const currentModelNames = useAppSelector(selectCurrentProjectModelNames);
    const modelObjects = useAppSelector(selectCurrentModelInputObjects);

    const { selectionState, unselect } = useSelection();
    const { getState, setXyzStateDirectly, removeViewsFromPlotDirectly, addViewToPlotDirectly } =
        useBaseXyz();
    const {
        createNewAnalyticalModelInProject,
        boundaryCreationStatus,
        resetBoundaryCreationStatus,
    } = useBoundaryCreationManager();

    const [boundaryName, setBoundaryName] = useState<string>(BOUNDING_BOX_DEFAULT_NAME);
    const [boundaryExtents, setBoundaryExtents] = useState<BoundingBox | undefined>(undefined);
    const [isEntireDomainBounded, setIsEntireDomainBounded] = useState<boolean>(false);
    const [errorState, setErrorState] =
        useState<BoundaryCreationDialogErrorState>(defaultErrorState);
    const updateboundaryExtentsIfItExists = useCallback(
        (updatedValue: Partial<BoundingBox>) => {
            if (boundaryExtents && updatedValue) {
                const updatedBoundary = { ...boundaryExtents, ...updatedValue };
                const newErrorState = detectBoundingBoxError(updatedBoundary);
                setErrorState(newErrorState);
                setBoundaryExtents((currentBoundary) => ({
                    ...currentBoundary!,
                    ...updatedValue,
                }));
            }
        },
        [boundaryExtents],
    );

    function discardCurrentBoundaryExtents() {
        removeViewsFromPlotDirectly([BOUNDARY_CREATION_VIEW_ID]);
        setBoundaryExtents(undefined);
    }

    function closeDialog() {
        unselect();
        discardCurrentBoundaryExtents();
        onClose();
    }

    async function handleCreateBoundary() {
        await createNewAnalyticalModelInProject(
            modelObjects,
            uuidv4() as ObjectId,
            boundaryName,
            boundingBoxToGtmBounds(boundaryExtents!),
        );
    }

    function adjustForUpdatedSelection() {
        if (selectionState?.position) {
            discardCurrentBoundaryExtents();
            setBoundaryExtents(
                computeBoundingBoxFromCenter(
                    selectionState.position,
                    getState().camera.radius / 10,
                ),
            );
        } else {
            unselect();
        }
    }

    useEffect(() => {
        if (!isEntireDomainBounded) {
            adjustForUpdatedSelection();
        }
    }, [selectionState]);

    useEffect(() => {
        if (boundaryExtents) {
            setXyzStateDirectly(getBoundingBoxSnapshot(boundaryExtents, BOUNDARY_CREATION_VIEW_ID));
            addViewToPlotDirectly(BOUNDARY_CREATION_VIEW_ID);
        }
    }, [boundaryExtents]);

    useEffect(() => {
        if (boundaryCreationStatus === BoundaryCreationState.SUCCESS) {
            closeDialog();
            resetBoundaryCreationStatus();
        }
    }, [boundaryCreationStatus]);

    const projectNameExists = currentModelNames
        .map((name) => name.toLowerCase())
        .includes(boundaryName.toLowerCase());
    const boundaryCreationStatusIsInProgress = boundaryCreationStatus !== undefined;
    const boundaryNameIsInvalid = projectNameExists && !boundaryCreationStatusIsInProgress;
    const isBoundingError = isBoundingBoxError(errorState);
    const title = boundaryExtents ? BOUNDING_BOX_TITLE : BOUNDING_BOX_NO_BOUNDS_TITLE;

    return (
        <Box p={2} paddingTop={1}>
            <Stack automation-id={applyTrace()}>
                <DialogHeader title={title} closeDialog={closeDialog} />
                {!boundaryExtents && (
                    <Typography variant="body2" align="center">
                        {NO_SELECTION_TEXT}
                    </Typography>
                )}

                {isBoundingError && boundaryExtents && (
                    <Alert
                        severity="error"
                        sx={{
                            marginBottom: (theme) => theme.spacing(2),
                            marginTop: (theme) => theme.spacing(1),
                        }}
                    >
                        {ERROR_MAX_MUST_GREATER_THAN_MIN}
                    </Alert>
                )}

                {boundaryExtents && (
                    <>
                        <BoundaryNameSection
                            isCurrentTextValid={!boundaryNameIsInvalid}
                            onChange={(event) => setBoundaryName(event.target.value)}
                        />
                        <EncloseModelCheckbox
                            objects={modelObjects}
                            onChange={updateboundaryExtentsIfItExists}
                            isEntireDomainBounded={isEntireDomainBounded}
                            setIsEntireDomainBounded={setIsEntireDomainBounded}
                        />
                        <MinMaxInputSection
                            boundingBox={boundaryExtents}
                            onChange={updateboundaryExtentsIfItExists}
                            boundingErrorState={errorState}
                            isEntireDomainBounded={isEntireDomainBounded}
                        />
                        <ButtonsSection
                            applyTrace={applyTrace}
                            isCreateDisabled={projectNameExists || isBoundingError}
                            onCreateBoundary={handleCreateBoundary}
                            onDeleteBoundary={closeDialog}
                        />
                    </>
                )}
                <BoundaryCreationModal
                    state={boundaryCreationStatus}
                    onRetry={handleCreateBoundary}
                    onClose={resetBoundaryCreationStatus}
                />
            </Stack>
        </Box>
    );
}

function DialogHeader({ title, closeDialog }: { title: string; closeDialog: () => void }) {
    return (
        <ListItem sx={{ padding: 0 }}>
            <ListItemText
                secondary={title}
                sx={{ textTransform: 'uppercase' }}
                secondaryTypographyProps={{ variant: 'body2' }}
            />
            <IconButton onClick={closeDialog} edge="end">
                <CloseIcon />
            </IconButton>
        </ListItem>
    );
}

function OutlinedTextFieldSizedTextProps(
    themeSpacing: number,
): Pick<TextFieldProps, 'InputProps' | 'InputLabelProps'> {
    return {
        InputProps: {
            sx: {
                fontSize: (theme) => theme.spacing(themeSpacing),
            },
        },
        InputLabelProps: {
            sx: { fontSize: (theme) => theme.spacing(themeSpacing) },
        },
    };
}

function BoundaryNameSection({
    isCurrentTextValid,
    onChange,
}: {
    isCurrentTextValid: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <FormControl>
            <TextField
                size="small"
                label={NAME_TITLE}
                variant="outlined"
                onChange={onChange}
                defaultValue={BOUNDING_BOX_DEFAULT_NAME}
                error={!isCurrentTextValid}
                helperText={isCurrentTextValid ? undefined : ERROR_DUPLICATE_NAME}
                sx={{ marginTop: (theme) => theme.spacing(2), width: '100%' }}
                {...OutlinedTextFieldSizedTextProps(1.75)}
            />
        </FormControl>
    );
}

interface MinMaxInputSectionProps {
    boundingBox: BoundingBox;
    onChange: (updatedValue: Partial<BoundingBox>) => void;
    boundingErrorState: BoundaryCreationDialogErrorState;
    isEntireDomainBounded: boolean;
}

function MinMaxInputSection({
    boundingBox,
    onChange,
    boundingErrorState,
    isEntireDomainBounded,
}: Readonly<MinMaxInputSectionProps>) {
    const disabledState: CoordinatesDisabledState = {
        isXDisabled: isEntireDomainBounded,
        isYDisabled: isEntireDomainBounded,
        isZDisabled: isEntireDomainBounded,
    };

    return (
        <>
            <CoordinatesInput
                label={MIN_TITLE}
                coordinateValues={{ x: boundingBox.xMin, y: boundingBox.yMin, z: boundingBox.zMin }}
                onChange={(axis, updatedValue) => onChange({ [`${axis}Min`]: updatedValue })}
                errorState={boundingErrorState.minErrorState}
                disabledState={disabledState}
            />
            <CoordinatesInput
                label={MAX_TITLE}
                coordinateValues={{ x: boundingBox.xMax, y: boundingBox.yMax, z: boundingBox.zMax }}
                onChange={(axis, updatedValue) => onChange({ [`${axis}Max`]: updatedValue })}
                errorState={boundingErrorState.maxErrorState}
                disabledState={disabledState}
            />
        </>
    );
}

function ButtonsSection({
    applyTrace,
    isCreateDisabled,
    onCreateBoundary,
    onDeleteBoundary,
}: {
    applyTrace: (id: string) => string;
    isCreateDisabled: boolean;
    onCreateBoundary: () => Promise<void>;
    onDeleteBoundary: () => void;
}) {
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                paddingTop: (theme) => theme.spacing(1.5),
                paddingBottom: (theme) => theme.spacing(1.5),
            }}
        >
            <Button
                size="small"
                color="primary"
                variant="outlined"
                fullWidth
                onClick={onDeleteBoundary}
            >
                {CANCEL_LABEL}
            </Button>
            <Button
                automation-id={applyTrace('create-boundary-button')}
                size="small"
                color="primary"
                variant="contained"
                fullWidth
                onClick={onCreateBoundary}
                disabled={isCreateDisabled}
            >
                {ACCEPT}
            </Button>
        </Stack>
    );
}

interface EncloseModelCheckboxProps {
    objects: ObjectIdWithVersion[];
    onChange: (updatedValue: Partial<BoundingBox>) => void;
    isEntireDomainBounded: boolean;
    setIsEntireDomainBounded: (value: boolean) => void;
}

function EncloseModelCheckbox({
    objects,
    onChange,
    isEntireDomainBounded,
    setIsEntireDomainBounded: setEntireDomainBounded,
}: Readonly<EncloseModelCheckboxProps>) {
    const { getSceneObjectsBoundingBox } = useSceneObjectDataManager();
    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEntireDomainBounded(event.target.checked);
        if (event.target.checked) {
            const fullyEnclosingExtents = getSceneObjectsBoundingBox(objects);
            if (fullyEnclosingExtents) {
                onChange(fullyEnclosingExtents);
            }
        }
    };

    const checkboxControl = (
        <Checkbox
            color="primary"
            size="medium"
            onChange={onCheckboxChange}
            checked={isEntireDomainBounded}
        />
    );

    return (
        <FormControlLabel
            control={checkboxControl}
            labelPlacement="end"
            label={ENCLOSE_ENTIRE_MODEL}
        />
    );
}
