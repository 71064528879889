import { useParams } from 'react-router-dom';

import { useLazyListFilesQuery } from 'src/apiClients/file/enhancedFileMiddleware';
import { PROJECT_EXTENSION } from 'src/strings';

export function useLazyListGtmProjectFiles() {
    const [ListFilesTrigger] = useLazyListFilesQuery();
    const { orgUuid, workspaceUuid } = useParams();

    async function ListGtmProjectFilesTrigger() {
        const { data } = await ListFilesTrigger({
            organisationId: orgUuid!,
            workspaceId: workspaceUuid!,
            fileName: `.${PROJECT_EXTENSION}`,
        });

        return data?.files;
    }

    return [ListGtmProjectFilesTrigger];
}
