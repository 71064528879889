import {
    GtmAnalyticalModel,
    GtmModel,
    isGtmAnalyticalModel,
    GtmProject,
    GtmAnalyticalModelSettings,
} from 'src/gtmProject/Project.types';
import { useSceneObjectDataManager } from 'src/hooks';
import { useDefectsLoadingManager } from 'src/hooks/defects/useDefectsLoadingManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import { clearIssues, removeIssuesForObject } from 'src/store/issues/issuesSlice';
import {
    setSelectedModelIndex,
    addModelToCurrentProjectAndSetSelected,
    clearProject,
    overwriteProject,
} from 'src/store/project/projectSlice';
import { selectCurrentProjectModels } from 'src/store/project/selectors';
import { useAppSelector, useAppDispatch } from 'src/store/store';
import { ObjectIdWithVersion } from 'src/types/core.types';

// Purpose: Other managers are meant to encapsulate logic for particular logical groups
//          of actions (e.g. project actions, visualization actions, detection actions, etc.).
//          Those actions are typically related to a single slice of our store. This manager
//          is meant to encapsulate logic that involves multiple slices, but are common operations.
export function useConglomerateActionManager() {
    const dispatch = useAppDispatch();
    const models = useAppSelector(selectCurrentProjectModels);

    const { renderAndRunDetectorsForModel, renderObject, runAllDetectorsOnObject } =
        useModelLoader();
    const { clearVisualizationAndIssues, clearVisualizationAndIssuesForObject } = useStoreCleaner();
    const { findObjectAndSetVersion, findObjectAndDelete } = useObjectManager();

    function loadProject(project: GtmProject) {
        clearVisualizationAndIssues();
        dispatch(clearProject());
        dispatch(overwriteProject({ project }));
    }

    function refreshVisualizationAndIssues(model: GtmModel | GtmAnalyticalModel) {
        clearVisualizationAndIssues();
        renderAndRunDetectorsForModel(model);
    }

    function switchCurrentModel(index: number) {
        dispatch(setSelectedModelIndex(index));
        refreshVisualizationAndIssues(models[index]);
    }

    function addNewModelAndSetAsCurrent(model: GtmModel) {
        dispatch(addModelToCurrentProjectAndSetSelected(model));
        refreshVisualizationAndIssues(model);
    }

    function updateObjectAndRunDetectors(
        object: ObjectIdWithVersion,
        analyticalModelSettings: GtmAnalyticalModelSettings,
    ) {
        findObjectAndSetVersion(object.id, object.version);
        runAllDetectorsOnObject(object, analyticalModelSettings);
    }

    function removeObject(object: ObjectIdWithVersion) {
        findObjectAndDelete(object.id);
        clearVisualizationAndIssuesForObject(object);
    }

    return {
        loadProject,
        // TODO: GEOM-727
        // refreshVisualizationAndIssues can become a private helper function once
        // we refactor away from holus-bolus re-running of detectors.
        refreshVisualizationAndIssues,
        switchCurrentModel,
        addNewModelAndSetAsCurrent,
        updateObjectAndRunDetectors,
        removeObject,
        renderObject,
        runAllDetectorsOnObject,
        clearVisualizationAndIssuesForObject,
    };
}

function useModelLoader() {
    const { loadGtmObject } = useSceneObjectDataManager();
    const { runAllDetectors } = useDefectsLoadingManager();

    const renderObject = (object: ObjectIdWithVersion) => loadGtmObject(object.id, object.version);
    const runAllDetectorsOnObject = (
        object: ObjectIdWithVersion,
        analyticalModelSettings: GtmAnalyticalModelSettings,
    ) => runAllDetectors(object, analyticalModelSettings);

    function renderAndRunDetectorsForModel(model: GtmAnalyticalModel | GtmModel) {
        if (isGtmAnalyticalModel(model)) {
            model.objects.forEach((object) => {
                if (!object.isAggregated) {
                    renderObject(object);
                }
                // We explicitly pass the detection settings because currently we cannot reliably obtain them where the
                // detectors are actually invoked.
                runAllDetectorsOnObject(object, model.analyticalModelSettings);
            });
            renderObject(model.aggregateGeometry);
            runAllDetectorsOnObject(model.aggregateGeometry, model.analyticalModelSettings);
        } else {
            model.inputObjects?.forEach(({ id, version }) => loadGtmObject(id, version));
        }
    }

    return {
        renderObject,
        runAllDetectorsOnObject,
        renderAndRunDetectorsForModel,
    };
}

function useStoreCleaner() {
    const { clearGtmObjects, removeGtmObject } = useSceneObjectDataManager();
    const dispatch = useAppDispatch();

    return {
        clearVisualizationAndIssues: () => {
            clearGtmObjects();
            dispatch(clearIssues());
        },
        clearVisualizationAndIssuesForObject: (object: ObjectIdWithVersion) => {
            removeGtmObject(object.id);
            dispatch(removeIssuesForObject(object.id));
        },
    };
}
