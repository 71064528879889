import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { Color, UpdateSnapshot, Vector3 } from '@local/webviz/dist/types/xyz';
import { ElementClass, LinesMode, ViewClass } from '@local/webviz/dist/xyz';

const DEFAULT_DEFECT_COLOR = [255, 0, 0] as Color;
const DEFAULT_DEFECT_OPACITY = 1;
const DEFAULT_HIGHLIGHT_COLOR = [248, 100, 100] as Color;

// TODO: this is a temporary workaround for line and point XYZ objects not supporting positionOffset.
// Just offset the point data instead.
function applyPositionOffset(vertices: Float32Array, positionOffset: Vector3): Float32Array {
    const offsetVerts = new Float32Array(vertices.length);
    for (let i = 0; i < vertices.length; i += 3) {
        offsetVerts[i] = vertices[i] + positionOffset[0];
        offsetVerts[i + 1] = vertices[i + 1] + positionOffset[1];
        offsetVerts[i + 2] = vertices[i + 2] + positionOffset[2];
    }
    return offsetVerts;
}

export function getLinesSnapshot(
    elementId: string,
    viewId: string,
    vertices: Float32Array,
    segments: Int32Array,
    positionOffset: Vector3,
    highlight?: boolean,
    color?: Color,
): UpdateSnapshot {
    // Set default color, depending on highlight.
    let actualColor = highlight ? DEFAULT_HIGHLIGHT_COLOR : DEFAULT_DEFECT_COLOR;
    // Allow override by parameter.
    actualColor = color ?? actualColor;
    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Lines,
            vertices: applyPositionOffset(vertices, positionOffset),
            segments,
            // positionOffset,
        },

        [viewId]: generateEntity(ViewClass.Lines, {
            id: viewId,
            element: elementId,
            mode: LinesMode.Lines,
            color: actualColor,
            width: highlight ? 8 : 4,
        }),
    };
}

export function getDefectedSurfaceSnapshot(
    elementId: string,
    viewId: string,
    vertices: Float32Array,
    indices: Int32Array,
    positionOffset: Vector3,
    highlight?: boolean,
): UpdateSnapshot {
    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Surface,
            vertices,
            triangles: indices,
            positionOffset,
        },

        [viewId]: generateEntity(ViewClass.Surface, {
            id: viewId,
            element: elementId,
            color: highlight ? DEFAULT_HIGHLIGHT_COLOR : DEFAULT_DEFECT_COLOR,
            wireframe: true,
            showFaces: false,
            opacity: DEFAULT_DEFECT_OPACITY,
        }),
    };
}

export function getPointsSnapshot(
    elementId: string,
    viewId: string,
    vertices: Float32Array,
    positionOffset: Vector3,
    highlight?: boolean,
): UpdateSnapshot {
    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Points,
            vertices: applyPositionOffset(vertices, positionOffset),
            // positionOffset,
        },

        [viewId]: generateEntity(ViewClass.Points, {
            id: viewId,
            element: elementId,
            color: highlight ? DEFAULT_HIGHLIGHT_COLOR : DEFAULT_DEFECT_COLOR,
            size: highlight ? 10 : 8,
        }),
    };
}
