import {
    getAvailableEvoInstances,
    getCurrentEvoInstance,
    setCurrentEvoInstance,
} from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { SelectMenu } from '@local/web-design-system/dist/components/SelectMenu';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

export const HubSelector = () => {
    const theme = useTheme();
    const evoInstances = getAvailableEvoInstances();
    const currentEvoInstance = getCurrentEvoInstance();
    const currentHubCode = currentEvoInstance?.hub?.code;
    const options = evoInstances.map(({ hub: { display_name, code } }) => ({
        label: display_name,
        key: code,
    }));

    const handleOnSelect = (hub: string | number) => {
        if (hub === currentHubCode) {
            return;
        }

        const evoInstance = evoInstances.find(({ hub: { code } }) => code === hub);
        if (evoInstance) {
            setCurrentEvoInstance({ org: evoInstance.org, hub: evoInstance.hub });
            window.location.replace(`/${evoInstance.org.id}/hub/${evoInstance.hub.code}`);
        }
    };

    if (options.length === 1) {
        return null;
    }

    return (
        <Box width="300px" mt={theme.spacing(2)}>
            <SelectMenu options={options} onSelect={handleOnSelect} selected={currentHubCode} />
        </Box>
    );
};
