import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import Button from '@mui/material/Button';

import { CLOSE, RETRY } from './CloseRetryActions.constants';

/**
 * Props for the CloseRetryActions component.
 *
 * @interface CloseRetryActionsProps
 * @property {() => void} onClose - Function to call when the close button is clicked.
 * @property {() => void} onRetry - Function to call when the retry button is clicked.
 */
export interface CloseRetryActionsProps {
    onClose: () => void;
    onRetry: () => void;
}

/**
 * A component that renders two buttons: one for closing and one for retrying an action.
 * The close button is styled as a secondary button, and the retry button is styled as a primary button.
 * The close button comes before the retry button (our use is left-to-right).
 *
 * @param {Readonly<CloseRetryActionsProps>} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export function CloseRetryActions({ onClose, onRetry }: Readonly<CloseRetryActionsProps>) {
    const applyTrace = useTrace('close-retry-actions');
    return (
        <>
            <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={onClose}
                automation-id={applyTrace('close-button')}
            >
                {CLOSE}
            </Button>
            <Button
                variant="text"
                size="small"
                color="primary"
                onClick={onRetry}
                automation-id={applyTrace('retry-button')}
            >
                {RETRY}
            </Button>
        </>
    );
}
