import { GtmAnalyticalModel, isGtmAnalyticalModel, GtmModel } from 'src/gtmProject';
import { assert } from 'src/utils/gtmAssert';

import { ProjectState } from './projectSlice.types';

export function selectCurrentModel(projectState: ProjectState): GtmModel | undefined {
    return projectState.current.project?.models?.[projectState.current.selectedModelIndex];
}

export function getCurrentModel(projectState: ProjectState): GtmModel {
    const currentModel = selectCurrentModel(projectState);
    if (currentModel) {
        return currentModel;
    }
    assert(false, 'Trying to select "current" model, but no model is selected.');
    return {} as GtmModel;
}

export function selectCurrentModelAsAnalyticalModel(
    projectState: ProjectState,
): GtmAnalyticalModel | undefined {
    const currentModel = selectCurrentModel(projectState);
    if (currentModel !== undefined && isGtmAnalyticalModel(currentModel)) {
        return currentModel as GtmAnalyticalModel;
    }
    return undefined;
}

export function getCurrentAnalyticalModel(projectState: ProjectState) {
    const currentModel = selectCurrentModelAsAnalyticalModel(projectState);
    if (currentModel) {
        return currentModel;
    }
    assert(false, 'Current model is not an analytical model');
    return {} as GtmAnalyticalModel;
}
