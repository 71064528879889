import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import {
    GtmMeshTransformationAction,
    GtmInitAggregateGeomParams,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';

import { useGooseContext } from '../useGooseContext';

/**
 * Initializes the aggregate model trigger.
 *
 * @param bounds - The bounds to use to initialize the aggregate. If the boundary id already exists
 * the existing boundary will be overwritten which can be used to reset the aggregate to empty without creating
 * new geoscience objects.
 * @returns A function that triggers the initialization of the aggregate.
 */
export function useLazyInitAggregate() {
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const gooseContext = useGooseContext();

    async function InitAggregateTrigger(params: GtmInitAggregateGeomParams) {
        const { data: initResult, isError: initIsError } = await GtmMeshTransformationTrigger(
            formGtmMeshTransformationBody(
                gooseContext!,
                GtmMeshTransformationAction.InitAggregateGeom,
                [],
                params,
            ),
        );
        return { initResult, initIsError };
    }

    return [InitAggregateTrigger];
}
