import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ExploreContentIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <path
            d="M11 2H17C19.2091 2 21 3.79086 21 6V11.5M11 2L3 10M11 2V7C11 8.65685 9.65685 10 8 10H3M3 10V16C3 18.2091 4.79086 20 7 20H11"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1766 18.1159C19.5993 17.4899 19.8462 16.7353 19.8462 15.9231C19.8462 13.7564 18.0897 12 15.9231 12C13.7564 12 12 13.7564 12 15.9231C12 18.0897 13.7564 19.8462 15.9231 19.8462C16.7353 19.8462 17.4899 19.5993 18.1159 19.1766L19.3158 20.3765C19.6087 20.6694 20.0836 20.6694 20.3765 20.3765C20.6694 20.0836 20.6694 19.6087 20.3765 19.3158L19.1766 18.1159ZM15.9231 18.6577C14.4128 18.6577 13.1885 17.4334 13.1885 15.9231C13.1885 14.4128 14.4128 13.1885 15.9231 13.1885C17.4334 13.1885 18.6577 14.4128 18.6577 15.9231C18.6577 17.4334 17.4334 18.6577 15.9231 18.6577Z"
            fill="currentColor"
        />
    </SvgIcon>
);
