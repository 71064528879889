import { useLazyGetObjectByIdQuery } from '@local/api-clients/src/goose/enhancedGooseClient';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import {
    createSnapshotToUpdateColorAndBackColor,
    createSnapshotToUpdateOpacity,
    createSnapshotToUpdateWireframe,
} from '@local/webviz/dist/context/snapshots';
import { Color as XyzColor } from '@local/webviz/dist/types/xyz';

import { useDefectsVisualizationManager } from 'src/hooks/defects';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { sceneObjectMap } from 'src/store/visualization/selectors';
import {
    addOrUpdateSceneObject,
    clearSceneObjects,
} from 'src/store/visualization/visualizationSlice';
import { ObjectIdWithVersion, ObjectId, VersionId } from 'src/types/core.types';
import { idAndVersionAreEqual } from 'src/utils/coreUtils';
import { gooseToGtmBoundingBox } from 'src/utils/typeTransformations';
import { getGlobalBoundingBox, BoundingBox } from 'src/utils/xyzUtils';
import { getMeshSnapshot, updateSurfaceMeshData } from 'src/visualization/context/generateData';

import { useGooseContext } from './useGooseContext';

export function useSceneObjectDataManager() {
    const dispatch = useAppDispatch();
    const sceneObjects = useAppSelector(sceneObjectMap);
    const [GetGooseObjectTrigger] = useLazyGetObjectByIdQuery();
    const { removeIssuesForObject, removeHighlightsForObject } = useDefectsVisualizationManager();
    const gooseContext = useGooseContext();

    const { setStateFromSnapshot, removeViewsFromPlotDirectly, addViewToPlotDirectly, getState } =
        useBaseXyz();

    async function loadGtmObject(objectId: string, versionId: string, name: string = '') {
        if (sceneObjects[objectId]?.version === versionId && !isObjectOnPlotByObjectId(objectId)) {
            addViewToPlotDirectly(`${objectId}`);
        } else {
            const elementId = `${objectId}-element`;
            setStateFromSnapshot(getMeshSnapshot(elementId, `${objectId}`), {});
            addViewToPlotDirectly(`${objectId}`);

            dispatch(
                addOrUpdateSceneObject([
                    objectId,
                    {
                        isLoading: true,
                        name,
                        id: objectId as ObjectId,
                        version: versionId as VersionId,
                    },
                ]),
            );

            const {
                data: objectData,
                isSuccess: gooseObjectSuccess,
                isLoading: gooseObjectLoading,
            } = await GetGooseObjectTrigger({
                objectId,
                version: versionId,
                workspaceId: gooseContext!.workspaceId,
                orgId: gooseContext!.orgId,
            });

            if (gooseObjectSuccess && !gooseObjectLoading) {
                dispatch(
                    addOrUpdateSceneObject([
                        objectId,
                        {
                            name: objectData.object.name,
                            gooseObject: objectData,
                        },
                    ]),
                );
                setStateFromSnapshot(updateSurfaceMeshData(elementId, objectData), {});
                if (objectData?.object?.extensions?.appearance) {
                    const { appearance } = objectData.object.extensions;
                    // TODO: all 3 at once?
                    if (appearance.wireframe !== undefined)
                        updateObjectWireframe(objectId, appearance.wireframe);
                    if (appearance.fillColor !== undefined)
                        updateObjectColor(objectId, appearance.fillColor);
                    if (appearance.opacity !== undefined)
                        updateObjectOpacity(objectId, appearance.opacity);
                }
            }
        }
    }

    // Color is [0-255, 0-255, 0-255]
    function updateObjectColor(objectId: string, color: XyzColor) {
        const colorUpdateSnapshot = createSnapshotToUpdateColorAndBackColor(
            `${objectId}`,
            color,
            color,
        );
        setStateFromSnapshot(colorUpdateSnapshot, {});
    }

    // Opacity is 0 to 1
    function updateObjectOpacity(objectId: string, opacity: number) {
        const snapshot = createSnapshotToUpdateOpacity(opacity, `${objectId}`);
        setStateFromSnapshot(snapshot, {});
    }

    function updateObjectWireframe(objectId: string, wireframe: boolean) {
        const snapshot = createSnapshotToUpdateWireframe(wireframe, `${objectId}`);
        setStateFromSnapshot(snapshot, {});
    }

    function removeGtmObject(objectId: string) {
        removeViewsFromPlotDirectly([objectId]);
        removeIssuesForObject(objectId, undefined);
        removeHighlightsForObject(objectId, undefined);
    }

    function clearGtmObjects() {
        setStateFromSnapshot(
            { plot: { views: [] }, selector: { active: false, primitiveId: -1 } },
            {},
        );
        dispatch(clearSceneObjects());
    }

    function isObjectOnPlotByObjectId(objectId: string): boolean {
        return getState().plot?.views?.some((view: string) => view.includes(objectId));
    }

    function getSceneObjectsBoundingBox(objects: ObjectIdWithVersion[]): BoundingBox | undefined {
        const boundingBoxes = Object.values(sceneObjects)
            .filter(
                (sceneObjectData) =>
                    objects.some((object) => idAndVersionAreEqual(object, sceneObjectData)) &&
                    sceneObjectData?.gooseObject?.object?.bounding_box,
            )
            .map((sceneObjectData) =>
                gooseToGtmBoundingBox(sceneObjectData?.gooseObject?.object?.bounding_box),
            );

        return getGlobalBoundingBox(boundingBoxes);
    }

    return {
        loadGtmObject,
        removeGtmObject,
        isObjectOnPlotByObjectId,
        clearGtmObjects,
        updateObjectColor,
        updateObjectOpacity,
        updateObjectWireframe,
        getSceneObjectsBoundingBox,
    };
}
