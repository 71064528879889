import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import { DEFAULT_LIST_MAX_HEIGHT_PX } from 'src/styles';

export function ModelsLoading() {
    return (
        <Box>
            <Stack
                sx={(theme) => ({
                    justifyContent: 'space-between',
                    padding: theme.spacing(3, 2),
                })}
                direction="row"
            >
                <Skeleton variant="text" sx={{ fontSize: '0.75rem' }} width={55} />
                <Skeleton variant="circular" width={26} height={26} />
            </Stack>

            <Box p={(theme) => theme.spacing(0, 2, 2, 2)}>
                <Stack
                    spacing={0.75}
                    sx={{
                        height: DEFAULT_LIST_MAX_HEIGHT_PX,
                        overflowY: 'auto',
                        borderRadius: 0.5,
                        border: 1,
                        borderColor: 'divider',
                    }}
                >
                    <Skeleton variant="rectangular" width="100%" height={36} />
                    <Skeleton variant="rectangular" width="100%" height={36} />
                    <Skeleton variant="rectangular" width="100%" height={36} />
                    <Skeleton variant="rectangular" width="100%" height={36} />
                    <Skeleton variant="rectangular" width="100%" height={36} />
                </Stack>
            </Box>
        </Box>
    );
}
