import { useDispatch } from 'react-redux';

import {
    setObjectVersion,
    setVolumeVersion,
    setAggregateVersion,
    removeVolume,
    removeObject,
    setIsAggregated,
} from 'src/store/project/projectSlice';
import { GtmObjectType, ProjectState } from 'src/store/project/projectSlice.types';
import { selectCurrentModelAsAnalyticalModel } from 'src/store/project/projectSliceUtils';
import { store } from 'src/store/store';
import { VersionId, ObjectId } from 'src/types/core.types';

export function useObjectManager() {
    const dispatch = useDispatch();

    const currentSelectedAnalyticalModel = () =>
        selectCurrentModelAsAnalyticalModel(store.getState().project as ProjectState);

    const currentObjects = () => currentSelectedAnalyticalModel()?.objects;

    const currentAggregate = () => currentSelectedAnalyticalModel()?.aggregateGeometry;

    const currentVolumes = () => currentSelectedAnalyticalModel()?.volumes;

    function isAggregate(objectId: ObjectId) {
        return currentAggregate()?.id === objectId;
    }

    function findVolumeIndex(objectId: ObjectId) {
        return currentVolumes()?.findIndex((volume) => volume.id === objectId) ?? -1;
    }

    function isVolume(objectId: ObjectId) {
        return findVolumeIndex(objectId) !== -1;
    }

    function findObjectIndex(objectId: ObjectId) {
        return currentObjects()?.findIndex((object) => object.id === objectId) ?? -1;
    }

    function isObject(objectId: ObjectId) {
        return findObjectIndex(objectId) !== -1;
    }

    function objectType(objectId: ObjectId) {
        if (isAggregate(objectId)) return GtmObjectType.AggregateGeometry;
        if (isVolume(objectId)) return GtmObjectType.VolumeShell;
        if (isObject(objectId)) return GtmObjectType.Object;
        return undefined;
    }

    function findObjectAndSetVersion(objectId: ObjectId, version: VersionId) {
        if (isAggregate(objectId)) dispatch(setAggregateVersion(version));

        const volumeIndex = findVolumeIndex(objectId);
        if (volumeIndex !== -1) dispatch(setVolumeVersion([volumeIndex, version]));

        const objectIndex = findObjectIndex(objectId);
        if (objectIndex !== -1) dispatch(setObjectVersion([objectIndex, version]));
    }

    function findObjectAndDelete(objectId: ObjectId) {
        if (isVolume(objectId)) dispatch(removeVolume(objectId));
        if (isObject(objectId)) dispatch(removeObject(objectId));
    }

    function findObjectAndSetAggregated(objectId: ObjectId, isAggregated: boolean) {
        const objectIndex = findObjectIndex(objectId);
        if (objectIndex !== -1) dispatch(setIsAggregated([objectIndex, isAggregated]));
    }

    function disaggregateAllObjects() {
        currentObjects()?.forEach((object) => findObjectAndSetAggregated(object.id, false));
    }

    return {
        isAggregate,
        isVolume,
        isObject,
        objectType,
        findObjectAndSetVersion,
        findObjectAndDelete,
        findObjectAndSetAggregated,
        disaggregateAllObjects,
    };
}
