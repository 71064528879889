import { ObjectIdWithVersion, GeoscienceObject } from 'src/types/core.types';

export function decorateNewObject(
    newObject: ObjectIdWithVersion,
    name: string,
    schema: string,
): GeoscienceObject {
    return {
        ...newObject,
        name,
        schema,
    };
}
