import { fonts } from '@local/web-design-system/dist/styles/fonts';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        root: {
            paddingTop: theme.spacing(3.12),
        },
        name: {
            ...fonts.breadcrumb,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        segment: {
            maxWidth: '60%',
            whiteSpace: 'nowrap',
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
            '&:last-of-type': {
                paddingLeft: 0,
            },
        },
        divider: {
            fontSize: 14,
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
            color: theme.palette.grey[500],
        },
        skeleton: {
            width: theme.spacing(8),
        },
        branchHistory: {
            width: 900,
            maxHeight: 650,
            overflowY: 'hidden',
            border: `1px solid rgba(151, 151, 151, 0.9)`,
        },
        breadcrumbRow: { padding: theme.spacing(1) },
        breadcrumb: {
            width: `${theme.spacing(2)}%`,
        },
    }) as const;

export const useStyles = makeStyles()(styles);
