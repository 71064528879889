import { useCallback, useEffect, useRef } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { DEFAULT_LIST_MAX_HEIGHT } from 'src/styles';

export interface IssueAccordionItemProps {
    title?: string;
    icon?: JSX.Element;
    items?: IssueAccordionItemProps[];
    id?: string;
    LeafElementType?: any;
    leafElementProps?: any;
}

const ListRow = ({
    items,
    itemIndex,
    setSize,
}: {
    items: IssueAccordionItemProps[];
    itemIndex: number;
    setSize: (index: number, size: number) => void;
}) => {
    const rowRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (rowRef.current) {
            setSize(itemIndex, rowRef.current.getBoundingClientRect().height);
        }
    }, [setSize, itemIndex]);

    return (
        <div ref={rowRef}>
            {items[itemIndex].LeafElementType({ ...items[itemIndex].leafElementProps })}
        </div>
    );
};

export const IssueVirtualizedList = ({ items }: { items: IssueAccordionItemProps[] }) => {
    const listRef = useRef<VariableSizeList | null>(null);
    const sizeMap = useRef<{ [index: number]: number }>({});
    const setSize = useCallback((index: number, size: number) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        if (listRef.current) {
            listRef.current.resetAfterIndex(index);
        }
    }, []);
    const getSize = (index: number) => sizeMap.current[index] || 50;

    return (
        <VariableSizeList
            ref={listRef}
            height={DEFAULT_LIST_MAX_HEIGHT}
            itemCount={items.length}
            itemSize={getSize}
            width="100%"
            itemData={items}
        >
            {({ data, index, style }: ListChildComponentProps) => (
                <div style={style}>
                    <ListRow items={data} itemIndex={index} setSize={setSize} />
                </div>
            )}
        </VariableSizeList>
    );
};
