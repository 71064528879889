/* eslint no-param-reassign: ["error", { "props": false }] */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialDetectionSettingsState } from 'src/store/ui/detectionSettings/selectors';

import { DetectionSettingsState } from './detectionSettings.types';

export const detectionSettingsSlice = createSlice({
    name: 'detectionSettings',
    initialState: initialDetectionSettingsState,
    reducers: {
        setAllDetectionSettings(
            state: DetectionSettingsState,
            { payload: newDetectionSettings }: PayloadAction<DetectionSettingsState>,
        ) {
            return newDetectionSettings;
        },
        setNeedleThresholdRatio(
            state: DetectionSettingsState,
            { payload: newNeedleThresholdRatio }: PayloadAction<string>,
        ) {
            state.degenerateTriangleSettings.needleThresholdRatio = newNeedleThresholdRatio;
        },
        setNeedleThresholdRatioValid(
            state: DetectionSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.degenerateTriangleSettings.needleThresholdRatioValid = isValidSetting;
        },
        setNeedleCollapseLength(
            state: DetectionSettingsState,
            { payload: newNeedleCollapseLength }: PayloadAction<string>,
        ) {
            state.degenerateTriangleSettings.needleCollapseLength = newNeedleCollapseLength;
        },
        setNeedleCollapseLengthValid(
            state: DetectionSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.degenerateTriangleSettings.needleCollapseLengthValid = isValidSetting;
        },
        setCapMinAngleDegrees(
            state: DetectionSettingsState,
            { payload: newCapMinAngleDegrees }: PayloadAction<string>,
        ) {
            state.degenerateTriangleSettings.capMinAngleDegrees = newCapMinAngleDegrees;
        },
        setCapMinAngleDegreesValid(
            state: DetectionSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.degenerateTriangleSettings.capMinAngleDegreesValid = isValidSetting;
        },
        setHoleSizeRatioTolerance(
            state: DetectionSettingsState,
            { payload: newHoleSizeRatioTolerance }: PayloadAction<string>,
        ) {
            state.holeSettings.holeSizeRatioTolerance = newHoleSizeRatioTolerance;
        },
        setHoleSizeRatioToleranceValid(
            state: DetectionSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.holeSettings.holeSizeRatioToleranceValid = isValidSetting;
        },
        setSelfIntersectionTolerance(
            state: DetectionSettingsState,
            { payload: newSelfIntersectionTolerance }: PayloadAction<string>,
        ) {
            state.selfIntersectionSettings.tolerance = newSelfIntersectionTolerance;
        },
        setSelfIntersectionToleranceValid(
            state: DetectionSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.selfIntersectionSettings.toleranceValid = isValidSetting;
        },
    },
});

export const {
    setAllDetectionSettings,
    setCapMinAngleDegrees,
    setCapMinAngleDegreesValid,
    setHoleSizeRatioTolerance,
    setHoleSizeRatioToleranceValid,
    setNeedleCollapseLength,
    setNeedleCollapseLengthValid,
    setNeedleThresholdRatio,
    setNeedleThresholdRatioValid,
    setSelfIntersectionTolerance,
    setSelfIntersectionToleranceValid,
} = detectionSettingsSlice.actions;
