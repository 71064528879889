import {
    fillBoundaryVertices,
    fillLineIndexSequence,
    generateVerticesFromPointIds,
    generateSurfaceWithDefectedTris,
    generateEdgeVertices,
    numberOfBoundaryVertices,
    uniqueEdges,
    NUM_COORDINATES_3D,
} from './geometry';
import { getDefectedSurfaceSnapshot, getLinesSnapshot, getPointsSnapshot } from './snapshots';
import { DefectsState } from './types';
import { toDefectViewId, toElementViewId } from './uid';

// We flatten all defects into a single view because XYZ cannot support hundred or thousands of views.
// Some models may return hundreds or thousands of defects, each of which would have received its own
// view+entity. That would cause a lockup in the browser.
// See: https://seequent.atlassian.net/browse/GEOM-573

// Use a special "index" for flattening defects.
const flattenedDefectIndex = -1;

export function generateTriangleDefects(
    defectName: string,
    { defects, surface, objectId }: DefectsState,
) {
    let allTriangles = defects.flatMap((defect) => defect.triangles);
    allTriangles = [...new Set(allTriangles)];

    const { vertices, triangles } = generateSurfaceWithDefectedTris(surface, allTriangles);
    const triangleDefectsViewId = toDefectViewId(objectId, defectName, flattenedDefectIndex);
    const surfaceSnapshot = getDefectedSurfaceSnapshot(
        toElementViewId(objectId, defectName, flattenedDefectIndex),
        triangleDefectsViewId,
        vertices,
        triangles,
        surface.positionOffset,
    );

    return {
        [triangleDefectsViewId]: surfaceSnapshot,
    };
}

// TODO: Generalize this function to create snapshots for any type of defect that is represented by
// a collection of edges, e.g. fin boundaries, naked boundaries, etc.
export function generateHoleDefects(
    defectName: string,
    { defects, surface, objectId }: DefectsState,
) {
    const totalNumberOfVertices = defects.reduce(
        (acc: number, hole) => acc + numberOfBoundaryVertices(hole.edges),
        0,
    );
    const vertices = new Float32Array(totalNumberOfVertices * 3);
    let currentOffset = 0;
    for (const hole of defects) {
        fillBoundaryVertices(vertices, hole.edges, surface, currentOffset);
        currentOffset += numberOfBoundaryVertices(hole.edges) * NUM_COORDINATES_3D;
    }
    const totalNumberOfEdges = defects.reduce((acc: number, hole) => acc + hole.edges.length, 0);
    const indices = new Int32Array(totalNumberOfEdges * 2);
    currentOffset = 0;
    let currentVertexIndex = 0;
    for (const hole of defects) {
        const indicesAdded = fillLineIndexSequence(
            indices,
            currentOffset,
            currentVertexIndex,
            hole.edges.length,
        );
        currentOffset += indicesAdded;
        currentVertexIndex += numberOfBoundaryVertices(hole.edges);
    }

    const holeLineSegmentViewId = toDefectViewId(objectId, defectName, flattenedDefectIndex);
    const linesSnapshot = getLinesSnapshot(
        toElementViewId(objectId, defectName, flattenedDefectIndex),
        holeLineSegmentViewId,
        vertices,
        indices,
        surface.positionOffset,
    );
    return {
        [holeLineSegmentViewId]: linesSnapshot,
    };
}

export function generatePointDefects(
    defectName: string,
    { objectId, defects, surface }: DefectsState,
) {
    let allPoints = defects.flatMap((defect) => defect.points);
    allPoints = [...new Set(allPoints)];

    const vertices = generateVerticesFromPointIds(allPoints, surface);
    const pointDefectsViewId = toDefectViewId(objectId, defectName, flattenedDefectIndex);
    const pointsSnapshot = getPointsSnapshot(
        toElementViewId(objectId, defectName, flattenedDefectIndex),
        pointDefectsViewId,
        vertices,
        surface.positionOffset,
    );

    return {
        [pointDefectsViewId]: pointsSnapshot,
    };
}

/**
 * Generate snapshots for edge defects where each edge represents an individual defect, e.g. for
 * non-manifold edges.
 * This function should not be used for defects that are represented by a collection of edges, e.g.
 * holes.
 */
export function generateEdgeDefects(
    defectName: string,
    { defects, surface, objectId }: DefectsState,
) {
    if (defects.length !== 1) throw Error('Bad edge defects data format');

    const allEdges = uniqueEdges(defects[0].edges);
    const numVertices = allEdges.length * 2;
    const vertices = new Float32Array(numVertices * 3);
    allEdges.forEach((edge, index) => {
        const edgeVertices = generateEdgeVertices(edge, surface);
        vertices.set(edgeVertices, index * 6);
    });

    const indices = new Int32Array(numVertices);
    for (let i = 0; i < numVertices; i += 1) {
        indices[i] = i;
    }

    const edgeDefectsViewId = toDefectViewId(objectId, defectName, flattenedDefectIndex);
    const linesSnapshot = getLinesSnapshot(
        toElementViewId(objectId, defectName, flattenedDefectIndex),
        edgeDefectsViewId,
        vertices,
        indices,
        surface.positionOffset,
    );

    return {
        [edgeDefectsViewId]: linesSnapshot,
    };
}
