import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button/Button';

import { ActionDialog } from 'src/components/ActionDialog/ActionDialog';
import { CANCEL_LABEL, DELETE_LABEL } from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export function DeleteDialog({
    title,
    shouldOpen,
    message,
    handleCancel,
    handleDelete,
}: {
    title: string;
    shouldOpen: boolean;
    message: string;
    handleCancel: () => void;
    handleDelete: () => void;
}) {
    const applyTrace = useTrace('delete-dialog');

    return (
        <ActionDialog
            open={shouldOpen}
            title={title}
            message={message}
            icon={<WarningIcon color="error" fontSize="large" />}
            actions={
                <>
                    <Button
                        automation-id={applyTrace('cancel-button')}
                        color="info"
                        autoFocus
                        onClick={handleCancel}
                    >
                        {CANCEL_LABEL}
                    </Button>
                    <Button
                        automation-id={applyTrace('delete-button')}
                        color="error"
                        variant="contained"
                        onClick={handleDelete}
                        startIcon={<DeleteIcon />}
                    >
                        {DELETE_LABEL}
                    </Button>
                </>
            }
        />
    );
}
