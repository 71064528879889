import { useParams } from 'react-router-dom';

import { useLazyGetFileByPathQuery } from 'src/apiClients/file/enhancedFileMiddleware';
import { GtmProject } from 'src/gtmProject/Project.types';
import { PROJECT_EXTENSION } from 'src/strings';

export function useLazyFetchGtmProjectFile() {
    const [FetchFileTrigger] = useLazyGetFileByPathQuery();
    const { orgUuid, workspaceUuid } = useParams();

    async function FetchGtmProjectFileTrigger(file: string) {
        const { data } = await FetchFileTrigger({
            organisationId: orgUuid!,
            workspaceId: workspaceUuid!,
            filePath: `${file}.${PROJECT_EXTENSION}`,
        });

        return !data
            ? undefined
            : ((await fetch(data.download).then((response) => response.json())) as GtmProject);
    }

    return [FetchGtmProjectFileTrigger];
}
