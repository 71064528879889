/* eslint no-param-reassign: ["error", { "props": false }] */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';

import {
    GtmEvoOutputObject,
    GtmProject,
    GtmHistoryEntry,
    GtmModel,
    GtmProjectInput,
    AggregatableObject,
    GtmAnalyticalModel,
} from 'src/gtmProject';
import { VersionId } from 'src/types/core.types';

import { CurrentProjectState, ProjectState } from './projectSlice.types';
import { getCurrentAnalyticalModel, getCurrentModel } from './projectSliceUtils';
// eslint-disable-next-line import/no-cycle
import { initialProjectState } from './selectors';

export const projectSlice = createSlice({
    name: 'project',
    initialState: initialProjectState,
    reducers: {
        setCurrentProject(
            projectState: ProjectState,
            { payload }: PayloadAction<Partial<CurrentProjectState>>,
        ) {
            return {
                ...projectState,
                current: {
                    ...projectState.current,
                    project: payload.project ?? ({} as GtmProject),
                },
            };
        },
        overwriteProject(
            projectState: ProjectState,
            { payload }: PayloadAction<Partial<CurrentProjectState>>,
        ) {
            return {
                ...projectState,
                current: {
                    ...projectState.current,
                    project: cloneDeep(payload.project) as GtmProject,
                },
            };
        },
        clearProject(_: ProjectState) {
            return initialProjectState;
        },
        setProjectName(projectState: ProjectState, { payload }: PayloadAction<string>) {
            return {
                ...projectState,
                current: {
                    ...projectState.current,
                    project: { ...projectState.current.project, name: payload },
                },
            };
        },
        setCurrentProjectVersionId(projectState: ProjectState, { payload }: PayloadAction<string>) {
            projectState.currentProjectVersionId = payload;
        },
        setCurrentModelName(projectState: ProjectState, { payload }: PayloadAction<string>) {
            getCurrentModel(projectState).name = payload;
        },
        setVolumes(projectState: ProjectState, { payload }: PayloadAction<GtmEvoOutputObject[]>) {
            getCurrentAnalyticalModel(projectState).volumes = payload;
        },
        clearVolumes(projectState: ProjectState) {
            getCurrentAnalyticalModel(projectState).volumes = [];
        },
        setShowVolumes(
            projectState: ProjectState,
            { payload: showVolumes }: PayloadAction<boolean>,
        ) {
            getCurrentAnalyticalModel(projectState).showVolumes = showVolumes;
        },
        setIsAggregated(
            projectState: ProjectState,
            { payload: [objectIndex, isAggregated] }: PayloadAction<[number, boolean]>,
        ) {
            getCurrentAnalyticalModel(projectState).objects[objectIndex].isAggregated =
                isAggregated;
        },
        setObjectVersion(
            projectState: ProjectState,
            { payload: [objectIndex, version] }: PayloadAction<[number, VersionId]>,
        ) {
            getCurrentAnalyticalModel(projectState).objects[objectIndex].version = version;
        },
        setVolumeVersion(
            projectState: ProjectState,
            { payload: [volumeIndex, version] }: PayloadAction<[number, VersionId]>,
        ) {
            getCurrentAnalyticalModel(projectState).volumes![volumeIndex].version = version;
        },
        setAggregateVersion(projectState: ProjectState, { payload }: PayloadAction<VersionId>) {
            getCurrentAnalyticalModel(projectState).aggregateGeometry!.version = payload;
        },
        removeObject(projectState: ProjectState, { payload: objectId }: PayloadAction<string>) {
            getCurrentAnalyticalModel(projectState).objects = getCurrentAnalyticalModel(
                projectState,
            ).objects.filter((obj) => obj.id !== objectId);
        },
        removeVolume(projectState: ProjectState, { payload: objectId }: PayloadAction<string>) {
            getCurrentAnalyticalModel(projectState).volumes = getCurrentAnalyticalModel(
                projectState,
            ).volumes?.filter((vol) => vol.id !== objectId);
        },
        addObject(
            projectState: ProjectState,
            { payload: object }: PayloadAction<AggregatableObject>,
        ) {
            getCurrentAnalyticalModel(projectState).objects.push(object);
        },
        addVolume(
            projectState: ProjectState,
            { payload: object }: PayloadAction<GtmEvoOutputObject>,
        ) {
            if (getCurrentAnalyticalModel(projectState).volumes) {
                getCurrentAnalyticalModel(projectState).volumes!.push(object);
            } else {
                getCurrentAnalyticalModel(projectState).volumes = [object];
            }
        },
        setParameterizedGeometryList(
            projectState: ProjectState,
            { payload: parameterizedGeometryList }: PayloadAction<GtmEvoOutputObject[]>,
        ) {
            getCurrentAnalyticalModel(projectState).parameterizedGeometryList =
                parameterizedGeometryList;
        },
        addOrReplaceParameterizedGeometryEntryByName(
            projectState: ProjectState,
            { payload: newParameterizedGeometry }: PayloadAction<GtmEvoOutputObject>,
        ) {
            const parameterizedGeometryList =
                getCurrentAnalyticalModel(projectState).parameterizedGeometryList ?? [];
            const index = parameterizedGeometryList.findIndex(
                (parameterizedGeometry) =>
                    parameterizedGeometry.name === newParameterizedGeometry.name,
            );

            if (index === -1) {
                parameterizedGeometryList.push(newParameterizedGeometry);
            } else {
                parameterizedGeometryList[index] = newParameterizedGeometry;
            }

            getCurrentAnalyticalModel(projectState).parameterizedGeometryList =
                parameterizedGeometryList;
        },
        setAggregate(
            projectState: ProjectState,
            { payload: object }: PayloadAction<GtmEvoOutputObject>,
        ) {
            getCurrentAnalyticalModel(projectState).aggregateGeometry = object;
        },
        appendHistoryEntry(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmHistoryEntry>,
        ) {
            projectState.current.project.history.push(payload);
        },
        setSelectedModelIndex(projectState: ProjectState, { payload }: PayloadAction<number>) {
            projectState.current.selectedModelIndex = payload;
        },
        setCurrentModelSelectedObjectIndex(
            projectState: ProjectState,
            { payload }: PayloadAction<number>,
        ) {
            projectState.current.currentModelSelectedObjectIndex = payload;
            projectState.current.isAggregateObjectSelected = false;
        },
        deselectCurrentModelSelectedObject(projectState: ProjectState) {
            projectState.current.currentModelSelectedObjectIndex = -1;
            projectState.current.isAggregateObjectSelected = false;
        },
        updateModelAtIndexForCurrentProject(
            projectState: ProjectState,
            {
                payload: [newEntries, index],
            }: PayloadAction<[Partial<GtmModel | GtmAnalyticalModel>, number]>,
        ) {
            projectState.current.project.models[index] = {
                ...projectState.current.project.models[index],
                ...newEntries,
            };
        },
        deleteModelInCurrentProject(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmModel>,
        ) {
            projectState.current.project.models = projectState.current.project.models.filter(
                (model) => model.id !== payload.id,
            );
        },
        addModelToCurrentProject(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmModel | GtmAnalyticalModel>,
        ) {
            projectState.current.project.models.push(payload);
        },
        addModelToCurrentProjectAndSetSelected(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmModel | GtmAnalyticalModel>,
        ) {
            projectState.current.project.models.push(payload);
            projectState.current.selectedModelIndex =
                projectState.current.project.models.length - 1;
        },
        addInputObjectsToModelInCurrentProject(
            projectState: ProjectState,
            { payload: [modelIndex, inputObjects] }: PayloadAction<[number, GtmProjectInput[]]>,
        ) {
            projectState.current.project.models[modelIndex].inputObjects?.push(...inputObjects);
        },
        removeInputObjectFromModelInCurrentProject(
            projectState: ProjectState,
            { payload: [modelIndex, inputObject] }: PayloadAction<[number, GtmProjectInput]>,
        ) {
            projectState.current.project.models[modelIndex].inputObjects =
                projectState.current.project.models[modelIndex].inputObjects?.filter(
                    (obj) => obj.id !== inputObject.id,
                );
        },
        setCurrentModelAggregateObjectAsSelected(projectState: ProjectState) {
            projectState.current.isAggregateObjectSelected = true;
            projectState.current.currentModelSelectedObjectIndex = -1;
        },
    },
});

export const {
    setCurrentProject,
    overwriteProject,
    clearProject,
    setProjectName,
    setCurrentProjectVersionId,
    setVolumes,
    clearVolumes,
    setShowVolumes,
    setIsAggregated,
    setSelectedModelIndex,
    setCurrentModelSelectedObjectIndex,
    deselectCurrentModelSelectedObject,
    appendHistoryEntry,
    setObjectVersion,
    setVolumeVersion,
    setAggregateVersion,
    removeObject,
    addVolume,
    setParameterizedGeometryList,
    addOrReplaceParameterizedGeometryEntryByName,
    setAggregate,
    addObject,
    removeVolume,
    updateModelAtIndexForCurrentProject,
    deleteModelInCurrentProject,
    addModelToCurrentProject,
    addModelToCurrentProjectAndSetSelected,
    addInputObjectsToModelInCurrentProject,
    removeInputObjectFromModelInCurrentProject,
    setCurrentModelAggregateObjectAsSelected,
    setCurrentModelName,
} = projectSlice.actions;
