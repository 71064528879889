import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AggregateIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <path
            d="M6.5 11L3.43362 13.0442C2.79475 13.4701 2.85435 14.4272 3.54111 14.7706L11.5526 18.7764C11.8341 18.9172 12.1655 18.9172 12.447 18.7764L20.3285 14.8356C21.0429 14.4784 21.071 13.4692 20.3776 13.073L17.5 11"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
            fill="none"
        />
        <path d="M15 10H13V4H11V10H9L12 14L15 10Z" fill="currentColor" />
    </SvgIcon>
);
