import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { DEFAULT_PANEL_WIDTH_PX } from 'src/styles';

const styles = (theme: Theme) =>
    ({
        root: {
            width: DEFAULT_PANEL_WIDTH_PX,
        },
        settingsSection: {
            padding: theme.spacing(2),
            gap: theme.spacing(1.5),
        },
        propertyInput: {
            height: '32px',
            alignItems: 'center',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
