export function getNextSequentialName(namePrefix: string, existingNames: string[]) {
    const lowerCaseNamePrefix = namePrefix.toLowerCase();
    const existingNamesSet = new Set(existingNames.map((name) => name.toLowerCase()));
    if (!existingNamesSet.has(lowerCaseNamePrefix)) {
        return namePrefix;
    }

    let nextNumber = 2;
    while (existingNamesSet.has(`${lowerCaseNamePrefix} ${nextNumber}`)) {
        nextNumber += 1;
    }
    return `${namePrefix} ${nextNumber}`;
}
