import { getCurrentEvoInstance } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';

export function getUrlConfig(forceOauthPrefix = false, includeClientSecret = false) {
    const { hub } = getCurrentEvoInstance();
    const url = `${hub?.url ?? ''}`;

    return {
        url,
        forceOauthPrefix,
        includeClientSecret,
    };
}
