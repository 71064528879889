import Tooltip from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

export function OverflowTooltipTypography({
    children,
    ...leftoverTypographyProps
}: TypographyProps) {
    const typographyRef = useRef<HTMLElement>(null);
    const [canShowTooltip, setCanShowTooltip] = useState(false);

    useEffect(() => {
        if (typographyRef.current) {
            setCanShowTooltip(
                typographyRef.current.scrollWidth > typographyRef.current.clientWidth ||
                    typographyRef.current.scrollHeight > typographyRef.current.clientHeight,
            );
        }
    }, [typographyRef.current, children]);

    return canShowTooltip ? (
        <Tooltip title={children}>
            <Typography ref={typographyRef} {...leftoverTypographyProps}>
                {children}
            </Typography>
        </Tooltip>
    ) : (
        <Typography ref={typographyRef} {...leftoverTypographyProps}>
            {children}
        </Typography>
    );
}
